import {Box, IconButton, TableCell, TableRow} from "@mui/material";
import {tableRowStyle} from "../EmployeesTable";
import {useDispatch} from "react-redux";
import {
  deleteUserFromOrganizationThunk,
  setOrgInfoAttr,
  setRentalContractsAccessState
} from "../../../../../redux/reducers/organizationInfoReducer";
import {hideMapDialog, showMapDialog} from "../../../../Main/Map/Common/Dialog/DialogReducer";
import {UserAccessDialog} from "./UserForm/UserAccessDialog/UserAccessDialog";
import {useState} from "react";
import {cannotIDo, getTariff} from "../../../../Main/Map/Common/tariffs";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {OwnerCrownIcon} from "./UserForm/OwnerCrownIcon";

export function getRolesString(user) {
  const roles = [];
  if (user.is_owner) {
    roles.push('Владелец')
  }
  if (user.is_admin) {
    roles.push('Админ')
  }
  if (user.is_editor && !user.is_admin) {
    roles.push('Редактор')
  }
  if (user.is_full_access_user && !user.is_editor) {
    roles.push('Полный просмотр')
  }
  if (roles.length === 0 && !user.only_mobile)
    roles.push('Просмотр')
  if (user.only_mobile) {
    roles.push('Только GisApp Mobile')
  }
  if (user.deny_access) {
    roles.push('Нет доступа')
  }
  return roles.join(', ');
}

const UserRow = ({user, fieldData, index}) => {
  const dispatch = useDispatch()
  const deleteDialogButtons = {'Да': {color: 'warning'}, 'Нет': {color: 'success'}}
  const [open, setOpen] = useState(false)
  const iAmOwner = !cannotIDo.ownerAction()
  const heIsMe = user.id === getTariff().userID;

  /** Обработчик открытия окна настроек пользователя. */
  const handleClickOpen = () => {
    setOpen(true)
  }

  /** Обработчик закрытия окна настроек пользователя. */
  const handleClose = () => {
    setOpen(false);
    dispatch(setRentalContractsAccessState(null, false))
  }

  /** Обработчик нажатия на кнопку редактирования доступа к арендам, дорогам, делянам. */
  const selectUserHandler = (user) => {
    dispatch(setOrgInfoAttr({selectedUser: user, createUserMode: false}))
  }

  /**
   * Обработчик нажатия кнопки изменения настроек доступа пользователя.
   *
   * @param {object} user - информация о пользователе с правами.
   * @example
   * {
   *     "id": 188,
   *     "last_name": "Иванов",
   *     "first_name": "Иван",
   *     "patronymic": "ИвановичИ",
   *     "phone": "+79000000000",
   *     "email": "test@mail.ru",
   *     "post": "Директор",
   *     "is_admin": true,
   *     "is_1c_user": false,
   *     "is_owner": true,
   *     "is_full_access_user": true,
   *     "custom_permissions": {
   *         "only_mobile": false,
   *         "show_only_removal_road": false,
   *         "show_developed_plot": true,
   *         "show_development_plot": true,
   *         "show_closed_plot": true,
   *         "show_reserved_plot": true,
   *         "show_archive_plot": true,
   *         "show_declared_plot": true,
   *         "show_removal_plot": true
   *     }
   * }
   */
  const editAccessHandler = (user) => {
    handleClickOpen(user)
  }

  const deleteButtonHandler = () => {
    dispatch(showMapDialog('warning', 'Удаление пользователя', "Вы уверены что " +
      "хотите удалить выбранного пользователя? ",
      deleteDialogButtons,
      (e) => {
        dispatch(hideMapDialog())
        if (e === 'Да') {
          dispatch(deleteUserFromOrganizationThunk(user.id))
        }
      }))
  }

  const iCanEdit = iAmOwner || !user.is_admin;

  return (
    <TableRow key={user.id} sx={tableRowStyle}>
      {open && <UserAccessDialog user={user} handleClose={handleClose}/>}
      <TableCell align="left">{index + 1}</TableCell>
      {fieldData.map(fieldObj =>
        <TableCell key={fieldObj.fieldName} align="left">{user[fieldObj.fieldName]}</TableCell>
      )}
      <TableCell align="left">
        {!user.is_owner
          ?
          getRolesString(user)
          :
          <OwnerCrownIcon/>
        }
      </TableCell>
      <TableCell align="left">
        <Box sx={{display: 'flex'}}>
          <IconButton
            disabled={!iCanEdit}
            title={'Редактировать данные сотрудника'}
            onClick={() => selectUserHandler(user)}
            color={'primary'}
          >
            <EditIcon/>
          </IconButton>
          <IconButton
            disabled={!iCanEdit && !heIsMe}
            title={'Изменить настройки доступа'}
            onClick={() => editAccessHandler(user)}
            color={'primary'}
          >
            <AdminPanelSettingsIcon/>
          </IconButton>
          {!user.is_owner &&
            <IconButton
              disabled={!iCanEdit}
              title={'Удалить сотрудника из организации'}
              onClick={deleteButtonHandler}
              color={'warning'}
            >
              <DeleteOutlineOutlinedIcon/>
            </IconButton>
          }
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default UserRow;
