import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Loading} from "../../../../Main/Map/Common/Loading";
import {cannotIDo, getTariff} from "../../../../Main/Map/Common/tariffs";

export function EmployeesTable({workspace, employees, setChanged}) {
  const [allChecked, setAllChecked] = useState(true)
  const [allUsers, setAllUsers] = employees;
  const iAMOwner = !cannotIDo.ownerAction()
  const organizationUsers = useSelector(state => state.organizationInfoReducer.organizationUsers)
  const myID = getTariff().userID;

  function selectAllHandler() {
    const newUsers = [...allUsers]
    newUsers.forEach(user => {
      if (user.id === myID || !user.is_admin || iAMOwner)
        user.checked = !allChecked;
    })
    setAllChecked(!allChecked)
    setChanged(true)
    setAllUsers(newUsers)
  }

  function changeState(user) {
    user.checked = !user.checked;
    const newUsers = structuredClone(allUsers)
    setAllUsers(newUsers)
    setChanged(true)
  }

  useEffect(() => {
    if (organizationUsers && !allUsers) {
      const arr = []
      organizationUsers.forEach(user => {
        const userNode = {
          id: user.id,
          name: `${user.last_name} ${user.first_name} ${user.patronymic}`,
          role: user.post,
          checked: Boolean(workspace.users.find(wuser => wuser.id === user.id)),
          is_admin: user.is_admin,
          is_owner: user.is_owner,
        }
        arr.push(userNode)
      })
      setAllUsers(arr)
    }
  }, [organizationUsers])

  if (!allUsers)
    return <Loading />

  let ind = 0;
  return (
    <Table>
      <TableHead>
        <TableRow>
            <TableCell width={'40%'}>
              <label>
                Сотрудник
              </label>
            </TableCell>
            <TableCell>
              <label>
                <Checkbox
                  checked={allChecked}
                  onChange={selectAllHandler}
                />
                Доступ
              </label>
            </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {allUsers.map(user =>
          <TableRow key={ind++} className="hoverTableRow">
              <TableCell>
                {user.name}
                <span style={{fontSize: '90%', color: 'grey', textTransform: 'lowercase'}}>, {user.role}</span>
              </TableCell>
              <TableCell>
                <Checkbox
                  disabled={!iAMOwner && user.is_admin && user.id !== myID}
                  checked={user.checked}
                  onChange={() => {
                    changeState(user)
                  }}
                />
              </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
