import React, {useState} from 'react';
import {IconButton, TableCell, TableRow} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {tableRowStyle} from "../EmployeesTable";
import {setOrgInfoAttr} from "../../../../../redux/reducers/organizationInfoReducer";
import {useDispatch, useSelector} from "react-redux";

const CreateUserRow = ({numColumns}) => {
  const currentUser = useSelector(state => state.userReducer.userInfo)
  const userIsAdminOrOwner = currentUser.is_admin || currentUser.is_owner
  const dispatch = useDispatch()

  const createUserModeToggler = () => {
    dispatch(setOrgInfoAttr({createUserMode: true, selectedUser: null}))
  }

  return (
    <TableRow
      sx={{...tableRowStyle, "&:hover": {backgroundColor: 'none'},}}
    >
      <TableCell colSpan={numColumns + 2} align="left">{''}</TableCell>
      <TableCell align="left">
        {userIsAdminOrOwner &&
          <IconButton
            onClick={createUserModeToggler}>
            <AddIcon sx={{color: 'primary.dark'}}/>
          </IconButton>
        }
      </TableCell>
    </TableRow>
  );
};

export default CreateUserRow;