import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {useState} from "react";
import {compileShortName} from "../../Main/Map/Common/MiscFunction";
import {cannotIDo, getRoleName, getTariff} from "../../Main/Map/Common/tariffs";
import {tableRowStyle} from "../Employees/EmployeesTable/EmployeesTable";
import {useDispatch, useSelector} from "react-redux";
import {
  getCurrentDateString,
  getCurrentMonthDateString,
  getPrevMonthDateString
} from "../../Main/Map/Common/DateTimeFunctions";
import {loadSentinelData, setOrgInfoAttr} from "../../../redux/reducers/organizationInfoReducer";
import {otherDataAPI} from "../../../api/api";
import {handleErrors} from "../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {setSnack} from "../../Main/Map/Common/Dialog/Snack/snackReducer";
import FunctionsOutlinedIcon from '@mui/icons-material/FunctionsOutlined';

function TinySumField({value, disabled = false, validate = null, endEditing = null, id = 'null', showSumm = null}) {
  const [data, setData] = useState(value)
  const [error, setError] = useState(false)

  return (
    <TextField
      id={id}
      error={error}
      value={data}
      autoComplete={'off'}
      disabled={disabled}
      inputProps={{
        style: {
          padding: 0,
          margin: `3px 3px 3px ${showSumm ? 0 : 5}px`,
          fontSize: '80%',
        },
      }}
      InputProps={{
        style: {paddingLeft: '3px'},
        startAdornment: showSumm &&
          <InputAdornment position="start" style={{marginRight: 0, fontSize: '16px'}}>
            <FunctionsOutlinedIcon fontSize={'inherit'}/>
          </InputAdornment>,
      }}
      onChange={e => {
        if (validate) {
          // noinspection JSValidateTypes
          setError(!validate(e.target.value))
        }
        setData(e.target.value)
      }}
      onBlur={e => {
        if (endEditing) {
          // noinspection JSValidateTypes
          endEditing(e.target.value)
        }
      }}
      onKeyDown={e => {
        if (e.key === 'Enter')
          setTimeout(() => {
            document.activeElement.blur()
          }, 0)
      }}
      label={''}
    />
  )
}

export function SentinelUsersTable() {
  const allData = useSelector(state => state.organizationInfoReducer.sentinelUsers)
  const {data} = allData;
  const loadingError = useSelector(state => state.organizationInfoReducer.sentinelUsers.loadingError)
  const iAmOwner = !cannotIDo.ownerAction()
  const myID = getTariff().userID;
  const dispatch = useDispatch()

  const noBorderStyle = {
    borderBottom: 0,
  }

  const cellStyle = {
    paddingTop: 0,
    paddingBottom: 0,
    lineHeight: 'inherit',
  }

  const datesSpanStyle = {
    whiteSpace: 'nowrap',
    fontSize: '80%',
  }

  if (loadingError) {
    return (
      <>
        <Alert severity={'error'}>Произошла ошибка. Нажмите кнопку ниже, чтобы повторить.</Alert>
        <Button
          variant={'contained'}
          style={{marginTop: '10px'}}
          onClick={() => {
            dispatch(setOrgInfoAttr({sentinelUsers: {loading: true, loadingError: false, data: null}}))
            loadSentinelData()
          }}
        >
          Повторить
        </Button>
      </>
    )
  }

  if (!data) return null;

  function changeCheckedUser(user) {
    const newValue = !user.settings_sentinel.is_on;
    const json = [{
      id: user.id,
      is_on: newValue,
      today_limit_count: user.settings_sentinel.today_limit_count || null,
    }]
    user.settings_sentinel.is_on = newValue;
    dispatch(setOrgInfoAttr({sentinelUsers: {...allData}}))
    otherDataAPI.sentinel.patchUserSettings(json).then(() => {
      dispatch(setOrgInfoAttr({sentinelUsers: {...allData}}))
      const userName = compileShortName(user.first_name, user.last_name, user.patronymic)
      dispatch(setSnack('success', `Доступ для пользователя "${userName}" изменен`))
    }).catch(err => {
      user.settings_sentinel.is_on = !user.settings_sentinel.is_on;
      dispatch(setOrgInfoAttr({sentinelUsers: {...allData}}))
      handleErrors(dispatch, err)
    })
  }

  function changeUserLimit(user, value) {
    const current = user.settings_sentinel.today_limit_count || '';
    if (validateInt(value) && current !== value) {
      const json = [{
        id: user.id,
        is_on: user.settings_sentinel.is_on,
        today_limit_count: value || null,
      }]
      otherDataAPI.sentinel.patchUserSettings(json).then(() => {
        const userName = compileShortName(user.first_name, user.last_name, user.patronymic)
        user.settings_sentinel.today_limit_count = value;
        dispatch(setSnack('success', `Дневной лимит для пользователя "${userName}" изменен`))
      }).catch(err => {
        handleErrors(dispatch, err)
      })
    }
  }

  function changeAllCheckedUsers() {
    const newValue = !allData.allChecked;
    allData.allChecked = !allData.allChecked;
    const json = []
    allData.data.users.map(user => {
      const bool = iAmOwner || !user.is_admin || user.id === myID;
      if (bool) {
        if (user.settings_sentinel.is_on !== newValue) {
          json.push({
            id: user.id,
            is_on: newValue,
            today_limit_count: user.settings_sentinel.today_limit_count || null,
          })
          user.settings_sentinel.is_on = newValue;
        }
      }
    })
    if (json.length) {
      otherDataAPI.sentinel.patchUserSettings(json).then(() => {
        dispatch(setOrgInfoAttr({sentinelUsers: {...allData}}))
        dispatch(setSnack('success', 'Доступ для пользователей установлен'))
      }).catch(err => {
        handleErrors(dispatch, err)
      })
    }
  }

  function validateInt(value) {
    if (value === null || value === '')
      return true;
    const reg = new RegExp('^[0-9]+$')
    if (!reg.test(value))
      return false;
    const int = parseInt(value)
    return !isNaN(int) && int > 0 && int <= 100000;
  }

  function setOrgGlobalSettingsData(data) {
    dispatch(setOrgInfoAttr({
      sentinelUsers: {
        ...allData,
        data: {
          ...allData.data,
          organization: {
            ...allData.data.organization,
            settings_sentinel: {
              ...allData.data.organization.settings_sentinel,
              ...data,
            }
          }
        }
      }
    }))
  }

  /*function saveGlobalDayLimit(value) {
    const current = data.organization.settings_sentinel.today_limit_count || '';
    if (validateInt(value) && current !== value) {
      const json = {
        is_on: data.organization.settings_sentinel.is_on || false,
        today_limit_count: parseInt(value) || null,
      }
      otherDataAPI.sentinel.patchOrgData(json).then(() => {
        setOrgGlobalSettingsData({today_limit_count: value})
        dispatch(setSnack('success', 'Дневной лимит для организации установлен'))
      }).catch(err => {
        handleErrors(dispatch, err)
      })
    }
  }*/

  function saveGlobalAccess() {
    const newValue = !data.organization.settings_sentinel.is_on;
    setOrgGlobalSettingsData({is_on: newValue})
    const json = {
      is_on: newValue,
      //today_limit_count: data.organization.today_limit_count || null,
    }
    otherDataAPI.sentinel.patchOrgData(json).then(() => {
      dispatch(setSnack('success', `Доступ организации к Sentinel ${newValue ? 'включен' : 'отключен'}`))
    }).catch(err => {
      setOrgGlobalSettingsData({is_on: !newValue})
      handleErrors(dispatch, err)
    })
  }

  let ind = 1;
  return (
    <>
      <Stack direction={'row'} alignItems={'center'}>
        <FormControlLabel
          control={
            <Switch disabled={!iAmOwner} checked={data.organization.settings_sentinel.is_on}
                    onChange={saveGlobalAccess}/>
          }
          label={`${!data.organization.settings_sentinel.is_on ? 'Включить' : 'Отключить'} доступ для организации`}
        />
      </Stack>
      <TableContainer sx={{overflowX: 'auto'}}>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2} style={cellStyle}>№</TableCell>
              <TableCell rowSpan={2} style={cellStyle}>ФИО</TableCell>
              <TableCell rowSpan={2} style={cellStyle}>Должность</TableCell>
              <TableCell rowSpan={2} style={cellStyle}>Роль</TableCell>
              <TableCell style={{...noBorderStyle, ...cellStyle}}>Запросы<br/>
                <span style={datesSpanStyle}>
                  {getPrevMonthDateString()}
                </span>
              </TableCell>
              <TableCell style={{...noBorderStyle, ...cellStyle}}>Запросы<br/>
                <span style={datesSpanStyle}>
                  {getCurrentMonthDateString()}
                </span>
              </TableCell>
              <TableCell style={{...noBorderStyle, ...cellStyle}}>Запросы<br/>
                <span style={datesSpanStyle}>
                  {getCurrentDateString()}
                </span>
              </TableCell>
              <TableCell style={{...noBorderStyle, ...cellStyle}}>
                Дневной лимит
              </TableCell>
              <TableCell style={{...noBorderStyle, ...cellStyle}}>
                Доступ
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={cellStyle}>
                <TinySumField value={data.organization.last_month_count} showSumm disabled/>
              </TableCell>
              <TableCell align="left" style={cellStyle}>
                <TinySumField value={data.organization.current_month_count} showSumm disabled/>
              </TableCell>
              <TableCell align="left" style={cellStyle}>
                <TinySumField value={data.organization.today_count} showSumm disabled/>
              </TableCell>
              <TableCell align="left" style={cellStyle}>
                <TinySumField
                  value={data.organization.settings_sentinel.today_limit_count_max || ''}
                  //validate={validateInt}
                  //endEditing={saveGlobalDayLimit}
                  //disabled={!iAmOwner}
                  disabled={true}
                />
              </TableCell>
              <TableCell align="center" style={cellStyle}>
                <IconButton size={'small'} disableRipple>
                  <Checkbox onChange={changeAllCheckedUsers} checked={allData.allChecked} color={'primary'}/>
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allData.data.users.map(user => {
              const disabled = user.id !== myID && !iAmOwner && user.is_admin;
              return (
                <TableRow key={ind} sx={tableRowStyle} disabled={true}>
                  <TableCell>{ind++}</TableCell>
                  <TableCell>
                  <span style={{whiteSpace: 'nowrap'}}>
                    {compileShortName(user.first_name, user.last_name, user.patronymic)}
                  </span>
                  </TableCell>
                  <TableCell>{user.post}</TableCell>
                  <TableCell><span style={{whiteSpace: 'nowrap'}}>{getRoleName(user)}</span></TableCell>
                  <TableCell>{user.last_month_count}</TableCell>
                  <TableCell>{user.current_month_count}</TableCell>
                  <TableCell>{user.today_count}</TableCell>
                  <TableCell>
                    <TinySumField value={user.settings_sentinel.today_limit_count || ''}
                                  validate={validateInt} disabled={disabled}
                                  endEditing={e => changeUserLimit(user, e)}
                    />
                  </TableCell>
                  <TableCell align={'center'}>
                    <IconButton size={'small'} disableRipple>
                      <Checkbox
                        color={'primary'}
                        disabled={disabled}
                        checked={user.settings_sentinel.is_on}
                        onChange={() => changeCheckedUser(user)}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
