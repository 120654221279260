import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Alert, Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography,} from "@mui/material";
import {Loading} from "../../../../Main/Map/Common/Loading";
import {otherDataAPI} from "../../../../../api/api";
import {handleErrors} from "../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {cannotIDo, getTariff} from "../../../../Main/Map/Common/tariffs";

const headers = [
  "",
  "Сотрудник",
  "Отведена",
  "Задекларирована",
  "В разработке",
  "Разработана",
  "Идёт вывозка",
  "Закрыта",
  "В архиве"
]
export const headerKeys = [
  "",
  "full_name",
  "show_reserved_plots",
  "show_declared_plots",
  "show_development_plots",
  "show_developed_plots",
  "show_removal_plots",
  "show_closed_plots",
  "show_archive_plots"
]

const HeaderCell = ({header, onHeaderChange, allChecked}) => {
  return (
    <TableCell style={{whiteSpace: 'nowrap'}}>
      {header === "Сотрудник" || header === "" ? header :
        <label>
          <Checkbox
            checked={allChecked}
            onChange={() => onHeaderChange(header)}
          />
          {header}
        </label>
      }
    </TableCell>
  )
}

export function CASPermissionsTable({workspace, employeesCas, setChanged}) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState({loading: false, error: false})
  const [employees, setEmployees] = employeesCas;
  const iAmOwner = !cannotIDo.ownerAction()
  const myId = getTariff().userID;

  useEffect(() => {
    if (employees === null && !loading.error && !loading.loading) {
      setLoading({loading: true, error: false})
      otherDataAPI.workspaces.getOrganizationCasStatusAccess(workspace.id).then(res => {
        setEmployees(structuredClone(res.data["users_plots"]))
        setLoading({loading: false, error: false})
      }).catch(err => {
        setLoading({loading: false, error: true})
        handleErrors(dispatch, err)
      })
    }
  })

  function isRowChecked(row) {
    return headerKeys.slice(2).some(key => row[key])
  }

  function handleRowCheckboxChange(rowIndex) {
    const row = employees[rowIndex];
    const allChecked = !headerKeys.slice(2).every(key => row[key]); // Проверка, если все чекбоксы в строке активированы
    const updatedData = employees.map((row, i) =>
      i === rowIndex
        ? headerKeys.slice(2).reduce((acc, key) => {
          acc[key] = allChecked;
          return acc;
        }, {...row})
        : row
    )
    setEmployees(updatedData)
    setChanged(true)
  }

  function handleHeaderChange(header) {
    const key = headerKeys[headers.indexOf(header)]
    const updatedData = [...employees]
    let notChecked = !employees.some(row => (iAmOwner || myId === row["id_user"] || !row.is_admin) ? row[key] : false)
    updatedData.forEach(row => {
      if (iAmOwner || myId === row["id_user"] || !row.is_admin) {
        row[key] = notChecked;
      }
    })
    setEmployees(updatedData)
    setChanged(true)
  }

  function handleCheckboxChange(rowIndex, columnIndex) {
    const key = headerKeys[columnIndex];
    const updatedData = employees.map((row, i) => i === rowIndex ? {
      ...row,
      [key]: !row[key]
    } : row)
    setEmployees(updatedData)
    setChanged(true)
  }

  if (loading.loading)
    return <Loading/>

  if (loading.error)
    return <>
      <Alert severity={'error'} style={{width: 'fit-content'}}>Произошла ошибка при загрузке списков доступа</Alert>
      <Button onClick={() => {
        setLoading({loading: false, error: false})
      }}>Повторить</Button>
    </>

  if (!employees)
    return null;

  if (employees.length === 0)
    return <Typography>Нет доступных сотрудников</Typography>

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, index) =>
            <HeaderCell
              key={index}
              header={header}
              onHeaderChange={handleHeaderChange}
              allChecked={
                index === 0
                  ?
                  false
                  :
                  employees.length > 0 && employees.some(
                    row => (iAmOwner || myId === row["id_user"] || !row.is_admin) ? row[headerKeys[index]] : false)
              }
            />
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {employees.map((row, rowIndex) => {
            const rowDisabled = !iAmOwner && row.is_admin && myId !== row["id_user"];
            return (
              <TableRow key={rowIndex} className="hoverTableRow">
                <TableCell>
                  <Checkbox
                    disabled={rowDisabled}
                    checked={isRowChecked(row)}
                    onChange={() => handleRowCheckboxChange(rowIndex)}
                  />
                </TableCell>
                {headerKeys.slice(1).map((key, cellIndex) =>
                  <TableCell key={cellIndex}>
                    {cellIndex === 0
                      ?
                      <>
                        <span>{`${row.full_name}`}</span>
                        <span style={{fontSize: '90%', color: 'grey', textTransform: 'lowercase'}}>, {row.post}</span>
                      </>
                      :
                      <Checkbox
                        disabled={rowDisabled}
                        checked={row[key]}
                        onChange={() => handleCheckboxChange(rowIndex, cellIndex + 1)}
                      />
                    }
                  </TableCell>
                )}
              </TableRow>
            )
          }
        )}
      </TableBody>
    </Table>
  )
}
