import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import ModalPage from "../../../../../Common/ModalPage";
import {
  deletingErrorString,
  errorString,
  firesNotificationRentSavingError,
  firesNotifications,
  quartersString,
  rebootString,
  saveString,
  settingsString,
  workspaceProperiesSaveNoForestryString,
  workspaceProperiesSaveNoQuartersString, workspaceSettingSaved,
  workspaceString
} from "../../../../Map/Common/Strings_RU";
import {modalWindowStyle} from "../../../../../Common/Styles";
import {Modal, Stack, Tab, Tabs, Typography} from "@mui/material";
import {
  closeWorkspaceSettingsDialog,
  newQuarterTemplate,
  nullRentItem,
  restartWPSLoading,
  setWorkspaceAllData,
  startWPSLoading,
  stopWPSLoading,
} from "./workspaceReducer";
import {dataAPI} from "../../../../../../api/api";
import {setSnack} from "../../../../Map/Common/Dialog/Snack/snackReducer";
import {djangoErrorsParse} from "../../../../../../utils/djangoErrorsParse";
import {deleteRentPrimeters, refreshRentPerimeters} from "../ProjectsCommon";
import {Loading} from "../../../../Map/Common/Loading";
import {refreshProjects} from "../projectsReducer";
import {WSRentBordersTab} from "./WSRentBordersTab";
import {WSFiresSettingsTab} from "./WSFiresSettingsTab";

function transformData(data) {
  const transformed = {...data};
  transformed.people = data.people.map(person => ({
    // Оставляем все свойства объекта person, кроме employee,
    // и добавляем свойство employee с значением id из объекта employee
    ...person, employee: person.employee.id
  }));
  return transformed;
}

function WSSWindow() {
  const state = useSelector(state => state.workspaceReducer)
  const workspace = state.node;
  const dispatch = useDispatch()
  const rentData = state.rentData;
  const firesData = state.firesData;
  const [curTab, setCurTab] = React.useState(0);

  useEffect(() => {
    if (workspace && !state.loading && !state.loaded) {
      dispatch(startWPSLoading())
      async function loadData() {
        try {
          const resRent = await dataAPI.workspaces.getLeaseBoundary(workspace.id)
          if (resRent) {
            const rentData = structuredClone(nullRentItem)
            if (resRent.data.region) {
              rentData.region = {id: resRent.data.region.id, name: resRent.data.region.name}
              if (resRent.data.x_forestry) {
                rentData.forestry = {id: resRent.data.x_forestry.id, name: resRent.data.x_forestry.name}
                if (resRent.data.x_district_forestry_and_number) {
                  resRent.data.x_district_forestry_and_number.map(quarter => {
                    const qua = structuredClone(newQuarterTemplate)
                    qua.districtForestry = {
                      id: quarter.x_district_forestry.id,
                      name: quarter.x_district_forestry.name
                    }
                    qua.numbers = quarter.number;
                    //FIXME некоторые квартала нумберы, некоторые - стринги. переводим все в стринг
                    qua.numbers.forEach(item => item.number = item.number.toString())
                    rentData.quarters.unshift(qua)
                  })
                }
              }
            }
            dispatch(setWorkspaceAllData(rentData))
          }
          dispatch(stopWPSLoading(null))
        } catch (err) {
          console.error(err)
          dispatch(setSnack('error', err.response?.data ? djangoErrorsParse(err.response.data) : errorString))
          dispatch(stopWPSLoading(err.message))
        }
      }
      loadData().then()
    }
  }, [state])

  if (!workspace) return null;

  function closeHandler() {
    dispatch(closeWorkspaceSettingsDialog())
  }

  function validateData(data) {
    if (data.radius === "") {
      return false
    }
    if (data.people.length === 0) {
      return false;
    }

    // Проверяем массив people
    for (const person of data.people) {
      // Проверяем, что employee существует и не пустой
      if (!person.employee.id) {
        return false;
      }
    }

    // Все проверки пройдены
    return true;
  }

  function saveHandler() {
    if (curTab === 1) { //Fires
      if (firesData != null) {
        if (firesData.notification_on && !validateData(firesData)) {
          dispatch(setSnack('error', "Проверьте корректность введённых данных"))
          return;
        }
        dataAPI.workspaces.settings.firesNotification.updateAll(workspace.id, transformData(firesData)).then(() => {
          dispatch(setSnack('success', 'Оповещения о пожарах успешно установлены'))
        }).catch(err => {
          console.error(err)
          dispatch(setSnack('error', 'Произошла ошибка при сохранении оповещений о пожарах'))
        })
        dispatch(closeWorkspaceSettingsDialog())
      }
    } else { //Rent borders
      if (!rentData.region) {
        dataAPI.workspaces.deleteLeaseBoundary(workspace.id).then(() => {
          deleteRentPrimeters(workspace, false, true)
          deleteRentPrimeters(workspace, true, false)
          dispatch(closeWorkspaceSettingsDialog())
          dispatch(refreshProjects())
        }).catch(err => {
          console.error(err)
          dispatch(setSnack('error', err.response?.data ? djangoErrorsParse(err.response.data) : deletingErrorString))
        })
        return;
      }
      if (!rentData.forestry) {
        dispatch(setSnack('error', workspaceProperiesSaveNoForestryString))
        return;
      }
      const quarters = []
      rentData.quarters.map(item => {
        if (item.numbers.length) quarters.push({
          x_district_forestry: item.districtForestry.id, number: item.numbers.map(number => number.id),
        })
      })
      if (!quarters.length) {
        dispatch(setSnack('error', workspaceProperiesSaveNoQuartersString))
        return;
      }

      const json = {
        "region": rentData.region.id, "x_forestry": rentData.forestry.id, "x_district_forestry_and_number": quarters,
      }

      async function saveAll() {
        try {
          const resRent = await dataAPI.workspaces.saveLeaseBoundary(workspace.id, json)
          if (resRent.data['perimeter_multipolygon'])
            refreshRentPerimeters(workspace, resRent.data['perimeter_multipolygon'], true)
          dispatch(setSnack('info', workspaceSettingSaved))
          dispatch(closeWorkspaceSettingsDialog())
        } catch (err) {
          console.error(err)
          dispatch(setSnack('error', err.response?.data ? djangoErrorsParse(err.response.data) : firesNotificationRentSavingError))
        }
      }
      saveAll().then()
    }
  }

  function reloadHandler() {
    dispatch(restartWPSLoading())
  }

  let fDisable = true;
  let i = 0;
  while (i < rentData.quarters.length) {
    const item = rentData.quarters[i];
    if (item.numbers.length) {
      fDisable = false;
      break;
    }
    i++;
  }

  return (
    <ModalPage
      title={settingsString}
      actionButtonHandler={state.loadingError ? reloadHandler : saveHandler}
      actionButtonName={state.loadingError ? rebootString : saveString}
      actionButtonDisabled={state.loading || !state.loaded}
      handleClose={closeHandler}
      customStyle={{...modalWindowStyle, minWidth: '700px', maxHeight: '80%', overflowY: 'auto'}}
    >
      <Stack direction={'row'} alignItems={'center'}>
        <Typography variant={'subtitle2'} style={{fontWeight: 400}} noWrap>
          {workspaceString + `: "${workspace.name}"`}
        </Typography>
        {(state.loading || state.saving) && <Loading text={''} style={{paddingLeft: '5px'}}/>}
      </Stack>
      <Tabs value={curTab} onChange={(e, value) => setCurTab(value)} sx={{pb: 2}}>
        <Tab label={quartersString} disableRipple/>
        <Tab label={firesNotifications} disableRipple/>
      </Tabs>
      {curTab === 0 && <WSRentBordersTab/>}
      {curTab === 1 && <WSFiresSettingsTab workspace={workspace}/>}
    </ModalPage>)
}

export function WorkspaceSettingsDialog() {
  const ModalWindow = React.forwardRef(() => <WSSWindow/>)
  const stateNode = useSelector(state => state.workspaceReducer.node)

  if (!stateNode) return null;

  return (<Modal open={true}>
    <ModalWindow/>
  </Modal>)
}
