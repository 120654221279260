import {Table, TableBody, TableContainer} from "@mui/material";
import TableHeadComponent from "./TableComponents/TableHeadComponent";
import WorkspaceRow from "./TableComponents/WorkspaceRow";
import {useSelector} from "react-redux";
import WorkspaceForm from "./TableComponents/WorkspaceForm";
import CreateWorkspaceRow from "./TableComponents/CreateWorkspaceRow";
import {cannotIDo} from "../../../Main/Map/Common/tariffs";

const WorkSpacesTable = () => {
  const organizationWorkspaces = useSelector(state => state.organizationInfoReducer.organizationWorkspaces)
  const createWorkspaceMode = useSelector(state => state.organizationInfoReducer.createWorkspaceMode)
  useSelector(state => state.organizationInfoReducer.selectedWorkspace)

  const iAmOwner = !cannotIDo.ownerAction()
  const WorkSpacesTableColumns = [
    {name: 'order', label: '№', width: '5%'},
    {name: 'number', label: 'Наименование', width: '20%'},
    {name: 'created_at', label: 'Дата создания', width: '20%'},
    {name: 'users', label: 'Сотрудники', width: '45%'},
    {name: 'buttons', label: '', width: '10%'},
  ]

  if (!organizationWorkspaces || cannotIDo.adminAction())
    return null;

  return (
    <TableContainer sx={{pb: 1}}>
      <Table size={"small"} sx={{maxWidth: '100%'}}>
        <TableHeadComponent WorkSpacesTableColumns={WorkSpacesTableColumns}/>
        <TableBody>
          {organizationWorkspaces.map((workspace, i) =>
            <WorkspaceRow key={i}
                          workspace={workspace}
                          WorkSpacesTableColumns={WorkSpacesTableColumns}
                          index={i}
                          iAmOwner={iAmOwner}
            />
          )}
          {!createWorkspaceMode
            ? <CreateWorkspaceRow WorkSpacesTableColumns={WorkSpacesTableColumns}/>
            : <WorkspaceForm WorkSpacesTableColumns={WorkSpacesTableColumns}/>
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default WorkSpacesTable;
