import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import BasicTabs from "./Tabs/BasicTabs";
import {useDispatch, useSelector} from "react-redux";
import {
  modulesSave,
  setAllAllowRentalContractPlotsThunk,
  setAllAllowRentalContractRoadsThunk,
  setAllAllowRentalContractThunk,
  setRentalContractsAccessState
} from "../../../../../../../redux/reducers/organizationInfoReducer";

export const UserAccessDialog = ({user, handleClose}) => {
  const dispatch = useDispatch()
  const rentalContractsAccessState = useSelector(state => state.organizationInfoReducer.rentalContractsAccessState)
  const allRentalContract = useSelector(state => state.organizationInfoReducer.allRentalContract)
  const hideSaveButton = useSelector(state => state.organizationInfoReducer.hideSaveButton)
  const modules = useSelector(state => state.organizationInfoReducer.allModules)

  /** Обработчик кнопки сохранения в зависимости от типа сущности (деляна, дорога, аренда) */
  function handleSave () {
    switch (rentalContractsAccessState.type) {
      case 'rental_contracts':
        dispatch(setAllAllowRentalContractThunk(user, allRentalContract))
        dispatch(setRentalContractsAccessState(null, false))
        break;
      case 'plots':
        dispatch(setAllAllowRentalContractPlotsThunk(user.id, allRentalContract))
        dispatch(setRentalContractsAccessState(null, false))
        break;
      case 'roads':
        dispatch(setAllAllowRentalContractRoadsThunk(user.id, allRentalContract))
        dispatch(setRentalContractsAccessState(null, false))
        break;
      case 'modules':
        modulesSave(user.id, modules)
        dispatch(setRentalContractsAccessState(null, false))
        break;
    }
  }

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={handleClose}
    >
      <AppBar sx={{position: 'relative'}}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
          <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
            Редактирование прав доступа для {
            user.last_name +
            (user.first_name ? ` ${user.first_name.charAt(0)}.` : '') +
            (user.patronymic ? `${user.patronymic.charAt(0)}.` : '') +
            ` (${user.phone})`
          }
          </Typography>
          {!hideSaveButton &&
            <Button autoFocus color="inherit" onClick={handleSave}>
              Сохранить
            </Button>
          }
        </Toolbar>
      </AppBar>
      <BasicTabs user={user}/>
    </Dialog>
  )
}
