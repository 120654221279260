import React from 'react';
import {IconButton, TableCell, TableRow} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useDispatch, useSelector} from "react-redux";
import {setOrgInfoAttr} from "../../../../../redux/reducers/organizationInfoReducer";

const CreateWorkspaceRow = ({WorkSpacesTableColumns}) => {
  const currentUser = useSelector(state => state.userReducer.userInfo)
  const userIsAdminOrOwner = currentUser.is_admin || currentUser.is_owner
  const dispatch = useDispatch()

  const ColumnComponent = ({columnName}) => {
    switch (columnName) {
      case 'order':
      case 'created_at':
      case 'users':
      case 'number':
        return ''
      case 'buttons':
        return (userIsAdminOrOwner) && (
          <IconButton
            title={'Создать рабочую область'}
            onClick={createWorkspaceModeToggler}>
            <AddIcon sx={{color: 'primary.dark'}}/>
          </IconButton>
        )
    }
  }

  const createWorkspaceModeToggler = () => {
    dispatch(setOrgInfoAttr({createWorkspaceMode: true, selectedWorkspace: null}))
  }

  return (
    <TableRow sx={{"&:last-child td, &:last-child th": {border: 0}}}>
      {WorkSpacesTableColumns.map((column) =>
        <TableCell key={column.name} align="left">
          <ColumnComponent key={column.name} columnName={column.name}/>
        </TableCell>
      )}
    </TableRow>
  );
};

export default CreateWorkspaceRow;