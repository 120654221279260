import {Alert, Button, Checkbox, Stack, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useEffect, useState} from "react";
import {Loading} from "../../../../../../../Main/Map/Common/Loading";
import {otherDataAPI} from "../../../../../../../../api/api";
import {handleErrors} from "../../../../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {useDispatch, useSelector} from "react-redux";
import {
  setOrgInfoAttr,
  setRentalContractsAccessState
} from "../../../../../../../../redux/reducers/organizationInfoReducer";

export function FourthTabAccessModules({user}) {
  const modules = useSelector(state => state.organizationInfoReducer.allModules)
  const [accessAll, setAccessAll] = useState(true)
  const [writeAll, setWriteAll] = useState(false)
  const [loading, setLoading] = useState({loading: false, error: false})
  const dispatch = useDispatch()
  const userIsReadOnly = !user.is_editor;
  const notWritableModules = ['transport_monitoring', '1c_sync', '1c_reports']

  useEffect(() => {
    if (!loading.loading && !loading.error) {
      setLoading({...loading, loading: true})
      otherDataAPI.organizationUsers.getModulesAccessInfo(user.id).then(res => {
        dispatch(setOrgInfoAttr({allModules: res.data.data}))
        setAccessAll(res.data.data.every(item => item.access))
        setWriteAll(res.data.data.every(item => item.write
          || notWritableModules.indexOf(item["modules_permissions"]["slug"]) !== -1))
        setLoading({loading: false, error: false})
      }).catch(err => {
        const dispatch = window.elz_dispatch;
        setLoading({loading: false, error: true})
        handleErrors(dispatch, err)
      })
    }
  }, [user.id])

  function handleSetAllAccess() {
    const arr = [...modules]
    Object.values(arr).forEach(item => {
      item.access = !accessAll;
      if (!item.access)
        item.write = false;
    })
    dispatch(setOrgInfoAttr({allModules: arr}))
    if (accessAll)
      setWriteAll(false)
    setAccessAll(!accessAll)
    dispatch(setRentalContractsAccessState('modules', true))
  }

  function handleSetAllWritable() {
    const arr = [...modules]
    Object.values(arr).forEach(item => {
      item.write = !writeAll;
      if (item.write)
        item.access = true;
    })
    dispatch(setOrgInfoAttr({allModules: arr}))
    if (!writeAll)
      setAccessAll(true)
    setWriteAll(!writeAll)
    dispatch(setRentalContractsAccessState('modules', true))
  }

  function accessItemClick(module) {
    module.access = !module.access;
    if (!module.access)
      module.write = false;
    dispatch(setOrgInfoAttr({allModules: [...modules]}))
    dispatch(setRentalContractsAccessState('modules', true))
  }

  function writableItemClick(module) {
    module.write = !module.write;
    if (module.write)
      module.access = true;
    dispatch(setOrgInfoAttr({allModules: [...modules]}))
    dispatch(setRentalContractsAccessState('modules', true))
  }

  if (loading.loading)
    return <Loading />

  if (loading.error)
    return (
      <Stack direction={'column'} gap={1} style={{width: 'fit-content'}}>
        <Alert severity={'error'}>Произошла ошибка при загрузке списка модулей.</Alert>
        <Button
          variant={'outlined'}
          onClick={() => {
            dispatch(setOrgInfoAttr({allModules: null}))
            setLoading({loading: false, error: false})
          }}
        >
          Повторить
        </Button>
      </Stack>
    )

  if (!modules)
    return null;

  let ind = 0;
  return (
      <Table>
        <TableHead style={{userSelect: 'none'}}>
          <TableRow>
            <TableCell>
              Модуль
            </TableCell>
            <TableCell>
              <label>
                <Checkbox
                  checked={accessAll}
                  onChange={handleSetAllAccess}
                />
                Просмотр
              </label>
            </TableCell>
            <TableCell>
              <label>
                <Checkbox
                  disabled={userIsReadOnly}
                  checked={writeAll}
                  onChange={handleSetAllWritable}
                />
                Редактирование
              </label>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {modules.map(module =>
            <TableRow key={ind++} className="hoverTableRow">
              <TableCell>
                {module["modules_permissions"].name}
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={module.access}
                  onChange={() => accessItemClick(module)}
                />
              </TableCell>
              <TableCell>
                {!notWritableModules.find(item => module['modules_permissions']['slug'] === item) &&
                  <Checkbox
                    disabled={userIsReadOnly}
                    checked={module.write}
                    onChange={() => writableItemClick(module)}
                  />
                }
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
  )
}
