import {useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FirstTabAccessLevel from "./FirstTabAccessLevel";
import SecondTabAccessPlots from "./SecondTabAccessPlots";
import ThirdTabAccessRoads from "./ThirdTabAccessRoads";
import {useDispatch, useSelector} from "react-redux";
import {DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  modulesSave,
  setAllAllowRentalContractPlotsThunk,
  setAllAllowRentalContractRoadsThunk,
  setAllAllowRentalContractThunk, setOrgInfoAttr,
  setRentalContractsAccessState
} from "../../../../../../../../redux/reducers/organizationInfoReducer";
import {CustomTabPanel} from "../../../../../../CustomTabPanel";
import {FourthTabAccessModules} from "./FourthTabAccessModules";
import {cannotIDo} from "../../../../../../../Main/Map/Common/tariffs";
import {FiveTabUserPassword} from "./FiveTabUserPassword";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function BasicTabs({user}) {
  const dispatch = useDispatch()
  const rentalContractsAccessState = useSelector(state => state.organizationInfoReducer.rentalContractsAccessState)
  const allRentalContract = useSelector(state => state.organizationInfoReducer.allRentalContract)
  const modules = useSelector(state => state.organizationInfoReducer.allModules)
  const [openDialog, setOpenDialog] = useState(false)
  const [newTabIndex, setNewTabIndex] = useState(0)
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    dispatch(setRentalContractsAccessState(null, false))
    if (rentalContractsAccessState.isChanged) {
      setOpenDialog(true)
      setNewTabIndex(newValue)
    } else {
      setValue(newValue)
    }
    dispatch(setOrgInfoAttr({hideSaveButton: newValue === 4}))
  }

  const handleDialogClose = (saveChanges) => {
    if (saveChanges) {
      switch (rentalContractsAccessState.type) {
        case 'rental_contracts': {
          dispatch(setAllAllowRentalContractThunk(user, allRentalContract))
          dispatch(setRentalContractsAccessState(null, false))
          break;
        }
        case 'plots': {
          dispatch(setAllAllowRentalContractPlotsThunk(user.id, allRentalContract))
          dispatch(setRentalContractsAccessState(null, false))
          break;
        }
        case 'roads': {
          dispatch(setAllAllowRentalContractRoadsThunk(user.id, allRentalContract))
          dispatch(setRentalContractsAccessState(null, false))
          break;
        }
        case 'modules': {
          modulesSave(user.id, modules)
          dispatch(setRentalContractsAccessState(null, false))
        }
      }
    }
    setOpenDialog(false)
    if (!saveChanges) return;
    setValue(newTabIndex)
  }

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Доступ к договорам" {...a11yProps(0)} />
          <Tab label="Доступ к делянам" {...a11yProps(1)} />
          <Tab label="Доступ к дорогам" {...a11yProps(2)} />
          <Tab label="Доступ к модулям" {...a11yProps(3)} disabled={!user.is_editor || !!cannotIDo.lightTariffActions()}/>
          <Tab label="Установка пароля" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <FirstTabAccessLevel user={user}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <SecondTabAccessPlots user={user}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ThirdTabAccessRoads user={user}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <FourthTabAccessModules user={user}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <FiveTabUserPassword user={user}/>
      </CustomTabPanel>
      <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
        <DialogTitle>{"Есть несохранённые изменения"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Вы хотите сохранить перед переходом на другую вкладку?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)}>Назад</Button>
          <Button onClick={() => handleDialogClose(true)} autoFocus>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
