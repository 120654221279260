import {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import {TitleCommonBlock} from "../Common/TitleCommonBlock";
import CurrentTariff from "./CurrentTariff/CurrentTariff";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import Workspaces from "./Workspaces/Workspaces";
import Employees from "./Employees/Employees";
import PersonalAccount from "./PersonalAccount/PersonalAccount";
import {Stack} from "@mui/material";
import AuthorizationData from "./AuthorizationData/AuthorizationData";
import {useDispatch, useSelector} from "react-redux";
import {
  getCurrentUserInfo,
  getOrganizationDataThunk,
  setOrgInfoAttr,
} from "../../redux/reducers/organizationInfoReducer";
import ExpansionModules from "./ExpansionModules/ExpansionModules";
import {useLocation} from "react-router";
import {cannotIDo, getTariff} from "../Main/Map/Common/tariffs";
import SentinelUsers from "./Sentinel/SentinelUsers";

const NewOrganizationPage = () => {
  const dispatch = useDispatch();
  const {state: redirectState} = useLocation()
  const [openedBlock, setOpenedBlock] = useState("none")
  const organizationData = useSelector((state) => state.organizationInfoReducer.organizationData)
  const personalAccountBlockRef = useRef(null);
  const readOnly = !!cannotIDo.editorAction()
  const iAmAdmin = !cannotIDo.adminAction()
  const tariff = getTariff()

  useEffect(() => {
    !organizationData && dispatch(getOrganizationDataThunk())
    dispatch(getCurrentUserInfo())
    return () => {
      dispatch(setOrgInfoAttr({
        sentinelUsers: {
          data: null,
          loading: false,
          loadingError: false,
          allCheked: false,
        },
      }))
    }
  }, [])

  // при переходе в ЛК по кнопке "Оплатить" из хедера, делаем автоскролл до блока "Аккаунт" и открываем окно оплаты
  useEffect(() => {
    if (redirectState?.blockName) {
      setOpenedBlock(redirectState?.blockName)
    }
    if (redirectState?.blockName && personalAccountBlockRef.current) {
      setTimeout(() => {
        personalAccountBlockRef.current.scrollIntoView()
      }, 400)
    }
  }, [redirectState, personalAccountBlockRef.current])

  if (!tariff)
    return null;

  return (
    <Box sx={{overflow: 'auto', maxHeight: '100%'}}>
      <TitleCommonBlock
        title={"Личный кабинет"}
        /*        introTour={organizationPageIntroTour}
                introTourName={"organizationPageIntroTour"}
                id={"organizationPageIntroTour"}*/
      />
      <Stack sx={{pl: 10, pr: 10, pt: 2, pb: 2}} gap={2}>
        {organizationData && (
          <>
            <Stack direction={'row'}>
              {!readOnly &&
                <CurrentTariff/>
              }
              <Stack gap={2} sx={{flex: 1}}>
                <PersonalInfo/>
                <AuthorizationData/>
              </Stack>
            </Stack>
            {iAmAdmin &&
              <>
                <Workspaces
                  openedBlock={openedBlock}
                  setOpenedBlock={setOpenedBlock}
                />
                <Employees
                  openedBlock={openedBlock}
                  setOpenedBlock={setOpenedBlock}
                />
                <SentinelUsers
                  openedBlock={openedBlock}
                  setOpenedBlock={setOpenedBlock}
                />
                <PersonalAccount
                  openedBlock={openedBlock}
                  setOpenedBlock={setOpenedBlock}
                />
                <ExpansionModules
                  openedBlock={openedBlock}
                  setOpenedBlock={setOpenedBlock}
                />
              </>
            }
          </>
        )}
      </Stack>
      {/*<IntroTour
        tourData={organizationPageIntroTour}
        tourName={"organizationPageIntroTour"}
      />*/}
    </Box>
  )
}

export default NewOrganizationPage;
