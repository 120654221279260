import {useEffect, useState} from 'react';
import {IconButton, InputAdornment, TableCell, TableRow, TextField, Tooltip, Typography} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {tableRowStyle} from "../../EmployeesTable";
import {
  addUserToOrganizationThunk,
  setOrgInfoAttr,
  updateUserDataThunk
} from "../../../../../../redux/reducers/organizationInfoReducer";
import {useDispatch, useSelector} from "react-redux";
import {RolesList} from "./RolesList/RolesList";
import {validateField} from "../../../../../../validations/validations";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const UserForm = ({fieldData, selectedUser}) => {
  const dispatch = useDispatch()
  const organizationUsers = useSelector(state => state.organizationInfoReducer.organizationUsers)
  const [userData, setUserData] = useState({
    first_name: '', last_name: '', patronymic: '', phone: '', email: '',
    post: '', is_admin: false, is_1c_user: false, is_owner: false, is_editor: false, is_full_access_user: false, only_mobile: false, deny_access: false
  })
  const [validationErrors, setValidationErrors] = useState({})

  useEffect(() => {
    if (selectedUser?.id) {
      setUserData(selectedUser)
    }
  }, [])

  const validation = () => {
    let errors = {}
    Object.keys(userData).forEach((item) => {
      let field = fieldData.find(field => item === field.fieldName)
      if (field && field.required) {
        errors[field.fieldName] = validateField(field.fieldName, userData[item], true)
      } else if (field && !field.required) {
        errors[field.fieldName] = validateField(field.fieldName, userData[item])
      }
    })
    if (Object.values(errors).every(value => value === '')) {
      return true
    } else {
      setValidationErrors(errors)
      return false
    }
  }

  const createUserHandler = () => {
    if (validation()) {
      dispatch(addUserToOrganizationThunk(userData))
    }
  }

  const isUserDataChanged = () => {
    let oldUserObj = organizationUsers.find(user => user.id === userData.id)
    return JSON.stringify(oldUserObj) !== JSON.stringify(userData)
  }

  const updateUserHandler = () => {
    isUserDataChanged() && validation() && dispatch(updateUserDataThunk(userData))
  }

  const undoEditUser = () => {
    dispatch(setOrgInfoAttr({createUserMode: false, selectedUser: null}))
  }

  return (
    <TableRow
      sx={{...tableRowStyle, "&:hover": {backgroundColor: 'none'},}}
    >
      <TableCell align="left">{''}</TableCell>
      {fieldData.map((field, i) => {
        let {fieldName, required, label, max_length} = field
        let errorText = validationErrors[fieldName]

        return (
          <TableCell key={i} align="left">
            <TextField
              type={'text'}
              id={'textField'}
              variant={'standard'}
              size={'small'}
              label={label}
              error={!!errorText}
              required={required}
              value={userData[fieldName] || ''}
              fullWidth
              inputProps={{maxLength: max_length,}}
              InputProps={{
                startAdornment: fieldName === 'phone' && <InputAdornment position="start">+7</InputAdornment>,
                endAdornment: !!errorText &&
                  <Tooltip title={<Typography sx={{fontSize: 15}}>{errorText}</Typography>}>
                    <ErrorOutlineOutlinedIcon sx={{color: 'red', cursor: 'pointer'}}/>
                  </Tooltip>,
              }}
              onChange={(e) => setUserData(prev => ({...prev, [fieldName]: e.target.value}))}
            />
          </TableCell>
        )
      })}
      <TableCell align="left">
        <RolesList setUserObj={setUserData} userObj={userData}/>
      </TableCell>
      <TableCell align="left">
        <IconButton
          title={'Сохранить'}
          onClick={() => {
            !selectedUser?.id ? createUserHandler() : updateUserHandler()
          }}
          color={'primary'}
        >
          <DoneIcon />
        </IconButton>
        <IconButton onClick={undoEditUser} title={'Отменить'} color={'error'}>
          <CloseIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default UserForm;
