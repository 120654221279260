import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {BasicTabs} from "./BasicTabs";
import {useState} from "react";
import {cannotIDo, getTariff} from "../../../../Main/Map/Common/tariffs";
import {addUsersInWorkspacesThunk} from "../../../../../redux/reducers/organizationInfoReducer";
import {useDispatch} from "react-redux";
import {headerKeys} from "./CASPermissionsTable";
import {otherDataAPI} from "../../../../../api/api";
import {handleErrors} from "../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {setSnack} from "../../../../Main/Map/Common/Dialog/Snack/snackReducer";

export function WorkspacePermissionsSettings({workspace, handleClose}) {
  const [curTab, setCurTab] = useState(0)
  const [employees, setEmployees] = useState(null)
  const [employeesCas, setEmployeesCas] = useState(null)
  const [changed, setChanged] = useState(false)
  const myID = getTariff().userID;
  const iAmOwner = !cannotIDo.ownerAction()
  const dispatch = useDispatch()

  function handleSave() {
    if (curTab === 0) {//employees
      let arr = []
      employees.forEach(employee => {
        if (employee.checked)
          if (employee.id === myID || !employee.is_admin || iAmOwner)
            arr.push(employee)
      })
      arr = arr.filter(user => user.checked).map(user => user.id)
      dispatch(addUsersInWorkspacesThunk(workspace.id, arr))
      setEmployeesCas(null)
    } else {//cas
      const arr = employeesCas.map(employee => {
        const data = {id: employee.id}
        headerKeys.slice(2).forEach(header => {
          data[header] = employee[header]
        })
        return data;
      })
      otherDataAPI.workspaces.setOrganizationCasStatusAccess(workspace.id, {users_plots: arr}).then(() => {
        dispatch(setSnack('success', 'Права доступа к делянам обновлены.'))
      }).catch(err => {
        handleErrors(dispatch, err)
      })
    }
    setChanged(false)
  }

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={handleClose}
    >
      <AppBar sx={{position: 'relative'}}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
          <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
            Редактирование прав доступа для "{workspace.number}"
          </Typography>
          <Button autoFocus color="inherit" onClick={handleSave}>
            Сохранить
          </Button>
        </Toolbar>
      </AppBar>
      <BasicTabs
        workspace={workspace}
        curTab={curTab}
        setCurTab={setCurTab}
        employees={[employees, setEmployees]}
        employeesCas={[employeesCas, setEmployeesCas]}
        changed={[changed, setChanged]}
        handleSave={handleSave}
      />
    </Dialog>
  )
}
