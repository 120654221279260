import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography,} from "@mui/material";
import {getAllRentalContract, getAllRentalContractThunk, setRentalContractsAccessState} from "../../../../../../../../redux/reducers/organizationInfoReducer";
import './TableRowStyles.css';
import {Loading} from "../../../../../../../Main/Map/Common/Loading";

const headers = [
    "Договор аренды",
    "Доступ",
];

const headerKeys = [
    "number",
    "is_allowed"
];

const HeaderCell = ({header, onHeaderChange, allChecked}) => {
    return (
        <TableCell>
            {header === "Договор аренды" ? header : (
                <label>
                    <Checkbox
                        checked={allChecked}
                        onChange={() => onHeaderChange(header)}
                    />
                    {header}
                </label>
            )}
        </TableCell>
    );
};

const FirstTabAccessLevel = ({user}) => {
    const dispatch = useDispatch();
    const rentalContracts = useSelector(state => state.organizationInfoReducer.allRentalContract.rental_contracts);
    const isLoading = useSelector(state => state.organizationInfoReducer.isLoading);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        dispatch(getAllRentalContractThunk(user.id));
    }, [dispatch, user.id]);


    useEffect(() => {
        setTableData(rentalContracts || []);
    }, [rentalContracts]);

    const handleHeaderChange = (header) => {
        const key = headerKeys[headers.indexOf(header)];
        const allChecked = !tableData.every(row => row[key]);
        const updatedData = tableData.map(row => ({
            ...row,
            [key]: allChecked
        }));
        setTableData(updatedData);
        dispatch(getAllRentalContract({rental_contracts: updatedData}))
        dispatch(setRentalContractsAccessState('rental_contracts', true));
    };

    const handleCheckboxChange = (rowIndex, columnIndex) => {
        const key = headerKeys[columnIndex];
        const updatedData = tableData.map((row, i) => i === rowIndex ? {
            ...row,
            [key]: !row[key]
        } : row);
        setTableData(updatedData);
        dispatch(getAllRentalContract({rental_contracts: updatedData}))
        dispatch(setRentalContractsAccessState('rental_contracts', true));
    };

    return <>
        {isLoading ? <Loading /> :
            rentalContracts?.length > 0 ?
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                <HeaderCell
                                    key={index}
                                    header={header}
                                    onHeaderChange={handleHeaderChange}
                                    allChecked={tableData.length > 0 && tableData.some(row => row[headerKeys[index]])}
                                />
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((row, rowIndex) => (
                            <TableRow key={rowIndex} className="hoverTableRow">
                                {headerKeys.map((key, cellIndex) => (
                                    <TableCell key={cellIndex}>
                                        {cellIndex === 0 ? row[key] : (
                                            <Checkbox
                                                checked={row[key]}
                                                onChange={() => handleCheckboxChange(rowIndex, cellIndex)}
                                            />
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                : (<Typography>Договоры аренды отсутствуют</Typography>)
        }
    </>
};

export default FirstTabAccessLevel;
