import {getMap1, setMap1} from "../GlobalObjects";
import {cuttingsAreasTemplate, getCuttingAreas} from "../../LeftPanel/Context/CAs/CuttingsAreas";
import {getMiscLayers, resetMiscLayers} from "../../LeftPanel/Context/MiscLayers/MiscLayersCommon";
import {resetBaseLayers} from "../../Overlay/BaseLayers/BaseLayersCommon";
import {
  removeAllRentBordersLayersAndCrearData,
  resetProjects
} from "../../LeftPanel/Context/Projects/ProjectsCommon";
import {destroySettingsTimer} from "./userSettings";
import {resetPillars} from "../../LeftPanel/Context/Infrastructure/Pillars/pillars";
import {resetRoads} from "../../LeftPanel/Context/Roads/roads";
import {resetAllUserLayers} from "../../LeftPanel/Context/MiscLayers/UserLayers/UserLayers";
import {resetTariff} from "./tariffs";
import {deleteAllJivoData} from "./JivoSite";
import {stopPencilDrawing} from "./Drawing/pencil_drawing";
import {resetAllWarehouses} from "../../LeftPanel/Context/Infrastructure/Warehouses/WarehouseCommon";
import {removeAllViolations} from "../../LeftPanel/Context/Infrastructure/Violations/ViolationCommon";
import {deleteRedArrow} from "./redArrow";
import {getSideToSideControl} from "../Blind/SideBySide";

export function garbageNomNom() {
  destroySettingsTimer()
  stopPencilDrawing(false)
  const map = getMap1()

  //remove loaded CAs
  const cas = getCuttingAreas()
  cas.layerArray.map(ca => {
    ca.off('click')
  })

  cas.layerArray = [];
  cas.archivedArray = [];
  cas.loading = false;
  cas.isLoading = false;
  cas.filters = cuttingsAreasTemplate.filters;

  removeAllRentBordersLayersAndCrearData()
  resetProjects()

  resetMiscLayers(getMiscLayers())
  resetAllUserLayers()

  resetBaseLayers()
  resetRoads()
  resetPillars()
  resetAllWarehouses()
  removeAllViolations()
  resetTariff()
  deleteAllJivoData()
  deleteRedArrow()
  if (getSideToSideControl())
    getSideToSideControl().remove()

  //Remove Zoom
  map.elz_zoom.deleteAllLayers()
  map.elz_zoom = null;
  delete map.elz_highlighted;

  if (map) {
    map.off('zoomend')
    map.off('moveend')
    map.off('click')
    map.off('pm:drawstart')
    map.off('pm:drawend')
    map.eachLayer(function (layer) {
      map.removeLayer(layer);
    });
    map.remove()
  }
  setMap1(null)
}
