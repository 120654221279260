import {useEffect, useState} from "react";
import '../../Map/Common/css/jivo.css';
import {jivoTittleString} from "./Strings_RU";

let loaded = false;
let loading = false;
let script2 = null;

export function deleteAllJivoData() {
  /*if (jivo_api.clearHistory) {
    window.jivo_api.clearHistory();
  }*/
  if (window['jivo_api'])
    window['jivo_api'].close();

  /*if (script2)
    script2.remove();
  const iframe = document.getElementById('jivo-iframe-container')
  if (iframe) {
    iframe.remove()
  }
  const global = document.getElementsByClassName('__jivoDesktopButton')
  if (global) {
    let ind = 0;
    while (ind < global.length) {
      global.item(ind).parentElement.parentElement.remove()
      ind++;
    }
  }*/
/*  loaded = false;
  loading = false;*/
}

export function JivoSite() {
  const [refresh, setRefresh] = useState(false)

  function jivo_onload() {
    window.jivo_onLoadCallback = () => {
      loaded = true;
      loading = false;
      setRefresh(!refresh)
    }
  }

  useEffect(() => {
    if (!loaded && !loading && !script2) {
      loading = true;
      script2 = document.createElement('script')
      script2.src = process.env.REACT_APP_JIVO_URL;
      script2.async = false;
      script2.onload = jivo_onload;
      document.body.appendChild(script2);
    }
  })

  if (!loaded || loading)
    return null;

  return (
    <div
      id={'jivo_custom_widget'}
      title={jivoTittleString}
      onClick={() => {window['jivo_api'].open()}}
    />
  )
}
