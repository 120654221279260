import {useDispatch, useSelector} from "react-redux";
import {
  getOrganizationUsersThunk,
  getOrganizationWorkspacesThunk, loadSentinelData, setOrgInfoAttr
} from "../../../redux/reducers/organizationInfoReducer";
import Box from "@mui/material/Box";
import {bottomBlockStyle, buttonBlockTitleStyle, titleStyle} from "../organizationPageStyles";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {Typography} from "@mui/material";
import {Loading} from "../../Main/Map/Common/Loading";

export const BottomBlockTitle = ({openedBlock, setOpenedBlock, blockName, title}) => {
  const dispatch = useDispatch()
  const organizationUsers = useSelector(state => state.organizationInfoReducer.organizationUsers)
  const organizationUsersLoading = useSelector(state => state.organizationInfoReducer.organizationUsersLoading)
  const organizationWorkspaces = useSelector(state => state.organizationInfoReducer.organizationWorkspaces)
  const organizationWorkspacesLoading = useSelector(state => state.organizationInfoReducer.organizationWorkspacesLoading)
  const sentinel = useSelector(state => state.organizationInfoReducer.sentinelUsers)

  const titleClickHandler = () => {
    openedBlock !== blockName ? setOpenedBlock(blockName) : setOpenedBlock(null)
    if (blockName === 'Employees' || blockName === 'Workspaces') {
      if (!organizationWorkspaces && !organizationWorkspacesLoading)
        dispatch(getOrganizationWorkspacesThunk())
      if (!organizationUsers && !organizationUsersLoading)
        dispatch(getOrganizationUsersThunk())
    }
    if (blockName === 'SentinelUsers' && !sentinel.data && !sentinel.loading && !sentinel.loadingError) {
      loadSentinelData()
    }
  }

  const showLoader = () => {
    switch (blockName) {
      case 'Employees':
        if (openedBlock === blockName && organizationUsersLoading) return true
        break;
      case 'Workspaces':
        if (openedBlock === blockName && organizationWorkspacesLoading) return true
        break;
      case 'SentinelUsers':
        if (openedBlock === blockName && sentinel.loading) return true;
    }
  }

  return (
    <Box sx={bottomBlockStyle}
         onClick={titleClickHandler}>
      {openedBlock !== blockName
        ? <ArrowRightIcon sx={{...titleStyle, fontSize: 30}}/>
        : <ArrowDropDownIcon sx={{...titleStyle, fontSize: 30}}/>
      }
      <Typography
        sx={{
          ...buttonBlockTitleStyle,
          color: openedBlock === blockName ? 'primary.dark' : '#808080'
        }}>{title}</Typography>
      {showLoader() &&
        <Box sx={{ml: 2}}>
          <Loading size={20} text={''}/>
        </Box>
      }
    </Box>
  )
}
