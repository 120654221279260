import React from 'react';
import Box from "@mui/material/Box";
import {modalWindowStyle} from "./Styles";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import {IconButton} from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

// общий шаблон модального окна
export const ModalPage = ({title, handleClose, actionButtonHandler, actionButtonName, actionButtonRemove,
                            actionButtonDisabled, children, customStyle, titleIconButton}) => {
  return (
    <>
      <Box style={!customStyle ? modalWindowStyle : customStyle}>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Stack direction={'row'} spacing={1} sx={{display: 'flex', alignItems: 'center',}}>
            <Typography sx={{fontWeight: 'bold', fontSize: 18}}>{title}</Typography>
            {titleIconButton || ''}
          </Stack>
          <IconButton
            size={'small'}
            sx={{p:0}}
            onClick={() => handleClose()}
          >
            <CloseIcon/>
          </IconButton>
        </Box>
        {children}
        {!actionButtonRemove &&
          <Button color={'primary'} variant={'contained'}
               disabled={actionButtonDisabled}
               onClick={() => actionButtonHandler()}
          >
            {actionButtonName}
          </Button>
        }

      </Box>
    </>
  )
}

export default ModalPage;
