import axios from "axios";
import TokenService from "./token.service";
import {getTMDatesURLPart} from "../components/Main/Map/Common/DateTimeFunctions";
import {blockUserForever} from "../redux/reducers/userBlockReducer";

export const domainName = process.env.REACT_APP_DOMAIN_NAME;
let configAPI = {
  baseURL: process.env.REACT_APP_BASE_URL,
}

const AxiosInstance = axios.create(configAPI)

// interceptor для request в котором проверяем наличие access токена и добавляем его в Authorization header
AxiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = TokenService.getLocalAccessToken()
    if (
      accessToken &&
      config.url !== "api/v1/auth/token/" &&
      config.url !== "api/v1/auth/token/refresh" &&
      config.url !== "api/v1/form_registration/"
    ) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error)
  }
)

// interceptor для response в котором обрабатываем ошибку 401 при истечении срока жизни токена, запрашиваем новый токен
// и сохраняем его в local storage
AxiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    const dispatch = window.elz_dispatch;
    if (err.response) {
      if (err.response.status === 401) {
        try {
          const resAccess = await authAPI.getNewAccessToken()
          const accessToken = resAccess.data.token;
          TokenService.updateLocalAccessToken(accessToken)
          return AxiosInstance(originalConfig)
        } catch (error) {
          if (error.response.status === 401) {
            window.alert(
              "Срок действия вашей авторизации истёк. Пожалуйста, обновите страницу."
            )
            return Promise.reject(err)
          }
          if (error?.response?.status === 403) {
            if (error.response?.data?.deny) { //если прям ваще доступ заблокирован
              dispatch(blockUserForever('login', 'Доступ заблокирован администратором. Пожалуйста, нажмите "ОК", чтобы перейти на страницу входа.'))
            }
          }
          return Promise.reject(err)
        }
      }
      /*if (err?.response?.status === 404) {
        dispatch(setSnack('error', 'Произошла ошибка. Возможно изменены права доступа. Пожалуйста, перезагрузите страницу.'))
      }*/
    }
    return Promise.reject(err)
  }
)

// список эндпоинтов связанных с авторизацией
export const authAPI = {
  login(username, password) {
    return AxiosInstance.post(`api/v1/auth/token/`, {
      username: username,
      password: password,
    })
  },
  verifyUser(refreshToken) {
    return axios.post(
      `api/v1/auth/token/verify/`,
      {token: refreshToken},
      configAPI
    );
  },
  getNewAccessToken() {
    return axios.post(
      "api/v1/auth/token/refresh/",
      {
        refresh: TokenService.getLocalRefreshToken(),
      },
      configAPI
    );
  },
  /*registerUser(payload) {
    return axios.post(
      `api/v1/auth/no_pass/`, payload, configAPI);
  },*/
  registerOrganization(data) {
    return AxiosInstance.post(`api/v1/form_registration/`, data, configAPI)
  },
  sendConfCode(payload) {
    return axios.post(
      `api/v1/auth/send_conf_code/`, payload, configAPI)
  },
  smsCodeCheck(payload) {
    return axios.post(
      `api/v1/auth/token_ph/`, payload, configAPI)
  },
}

// эндпоинты Юры
export const otherDataAPI = {
  references: {
    getRegions() {
      return AxiosInstance.get("api/v1/dclt/regions/", configAPI);
    },
    getForestry(regionId) {
      return AxiosInstance.get(`api/v1/dclt/forestry/?region=${regionId}`, configAPI);
    },
    getDistrictForestry(forestryId) {
      return AxiosInstance.get(`api/v1/dclt/district_forestry/?forestry=${forestryId}`, configAPI);
    },
    getUniqueLocations(districtForestryId) {
      return AxiosInstance.get(`api/v1/dclt/unique_locations/?district_forestry=${districtForestryId}`, configAPI);
    },
    /*getPurposes() {
      return AxiosInstance.get("api/v1/dclt/purposes/", configAPI);
    },
    getCuttingForms() {
      return AxiosInstance.get("api/v1/dclt/cutting_forms/", configAPI);
    },*/
    getCuttingTypes() {
      return AxiosInstance.get("api/v1/dclt/cutting_types/", configAPI);
    },
    getNameForestObjects() {
      return AxiosInstance.get("api/v1/dclt/name_forest_objects/", configAPI);
    },
    getCategoryProtectForests() {
      return AxiosInstance.get("api/v1/dclt/category_protect_forests/", configAPI);
    },
    getMeasures() {
      return AxiosInstance.get("api/v1/dclt/measure/", configAPI);
    },
    getGovernmentAgencies(regionId) {
      return AxiosInstance.get(`api/v1/dclt/government_agencies/?region=${regionId}`, configAPI);
    },
    getDeclarantPersons() {
      return AxiosInstance.get("api/v1/dclt/declarant_persons/", configAPI);
    },
    getTypeUseForests() {
      return AxiosInstance.get("api/v1/dclt/type_use_forests/", configAPI);
    },
    getAlternativeForestriesByRegionId(regionId) {
      return AxiosInstance.get(`api/v1/apdln/x_forestry_web/?region=${regionId}`, configAPI)
    },
    getAlternativeDistrictForestryByAltForestryId(altForestryId) {
      return AxiosInstance.get(`api/v1/apdln/x_district_forestry_web/?x_forestry=${altForestryId}`, configAPI)
    },
    getAltDistrictForestryWithQuartersList(altDistrictForestryId) {
      return AxiosInstance.get(`api/v1/apdln/x_district_forestry_web/${altDistrictForestryId}`, configAPI)
    },
    getVarieties() {
      return AxiosInstance.get('api/v1/stockpile_ph/varieties/', configAPI)
    },
    getSpecies() {
      return AxiosInstance.get('api/v1/stockpile_ph/species/', configAPI)
    },
    getSpeciesLength() {
      return AxiosInstance.get('api/v1/stockpile_ph/length/', configAPI)
    },
  },
  organization: {
    getOrganizationData() {
      return AxiosInstance.get(`api/v1/organizations/my_organization/`, configAPI);
    },
    getOrganizationUsers() {
      return AxiosInstance.get(`api/v1/organizations/all_users_from_organization/`, configAPI);
    },
    changeOrgData(data, orgId) {
      return AxiosInstance.patch(`api/v1/organizations/${orgId}/`, data, configAPI)
    },
  },
  organizationUsers: {
    addUserToOrganization(userData) {
      return AxiosInstance.post(`api/v1/users/`, userData, configAPI)
    },
    updateUserData(userData) {
      return AxiosInstance.patch(`api/v1/users/${userData.id}/`, userData, configAPI)
    },
    deleteUserFromOrganization(userId) {
      return AxiosInstance.delete(`api/v1/users/${userId}/`, configAPI)
    },
    getAllRentalContract(userId) {
      return AxiosInstance.get(`api/v1/users/${userId}/rental_contracts/`, configAPI)
    },
    setAllAllowRentalContract(userId, data) {
      return AxiosInstance.put(`api/v1/users/${userId}/rental_contracts/`, data, configAPI)
    },
    getAllAllowedRentalContractPlots(userId) {
      return AxiosInstance.get(`api/v1/users/${userId}/rental_contracts_plots/`, configAPI)
    },
    setAllAllowRentalContractPlots(userId, data) {
      return AxiosInstance.patch(`api/v1/users/${userId}/rental_contracts_plots/`, data, configAPI)
    },
    getAllAllowedRentalContractRoads(userId) {
      return AxiosInstance.get(`api/v1/users/${userId}/rental_contracts_roads/`, configAPI)
    },
    setAllAllowRentalContractRoads(userId, data) {
      return AxiosInstance.patch(`api/v1/users/${userId}/rental_contracts_roads/`, data, configAPI)
    },
    getModulesAccessInfo(userId) {
      return AxiosInstance.get(`/api/v1/users/${userId}/module_permissions/`, configAPI)
    },
    setModulesAccessInfo(userId, data) {
      return AxiosInstance.patch(`/api/v1/users/${userId}/module_permissions/`, data, configAPI)
    },
    getUserChangeInfo(userId) {
      return AxiosInstance.get(`/api/v1/auth/${userId}/change_password_by_admin/`, configAPI)
    },
    setUserPasswordByAdmin(userId, json) {
      return AxiosInstance.post(`/api/v1/auth/${userId}/change_password_by_admin/`, json, configAPI)
    },
  },
  sentinel: {
    getSentinelUsersData() {
      return AxiosInstance.get('/api/v1/organizations/sentinel_count/', configAPI)
    },
    patchOrgData(json) {
      return AxiosInstance.patch('/api/v1/organizations/change_sentinel_settings/', json, configAPI)
    },
    patchUserSettings(json) {
      return AxiosInstance.patch('/api/v1/users/change_sentinel_settings/', json, configAPI)
    }
  },
  user: {
    getUserInfo() {
      return AxiosInstance.get("api/v1/users/me_info/", configAPI);
    },
    saveUserSettings(settings) {
      return AxiosInstance.post('api/v1/users/map_settings/', {map_settings: settings}, configAPI)
    },
    /*saveFullName(data) {
      return AxiosInstance.patch(`api/v1/users/me_info/`, data, configAPI)
    },*/
    setNewPasswordWithCode(data) {
      return AxiosInstance.post(`api/v1/auth/set_new_password/`, data, configAPI)
    },
    setNewPasswordWithOldPassword(data) {
      return AxiosInstance.post(`api/v1/auth/set_new_password_by_old_psw/`, data, configAPI)
    },
  },
  api1C: {
    syncWith1() {
      return AxiosInstance.get(`api/v1/apdln/sync_with_1c/sync_data`, configAPI)
    },
    syncWith1CPeriod(orgId, period) {
      return AxiosInstance.patch(`api/v1/organizations/${orgId}/change_auto_sync_1c/`, {auto_sync_1c: period},
        configAPI)
    },
    requestConnectionWith1C() {
      return AxiosInstance.get(`api/v1/apdln/sync_with_1c/connect_1c/`, configAPI)
    },
    requestDisconnect1C() {
      return AxiosInstance.get(`api/v1/apdln/sync_with_1c/disconnect_1c/`, configAPI)
    },
  },
  workspaces: {
    getOrganizationWorkspaces() {
      return AxiosInstance.get(`api/v1/dclt/rental_contracts_2/`, configAPI)
    },
    addUsersInWorkspaces(workspaceId, usersList) {
      return AxiosInstance.post(`api/v1/dclt/rental_contracts_2/${workspaceId}/users/`, {users: usersList}, configAPI)
    },
    getOrganizationCasStatusAccess(id) {
      return AxiosInstance.get(`api/v1/dclt/rental_contracts_2/${id}/user_plot/`, configAPI)
    },
    setOrganizationCasStatusAccess(id, data) {
      return AxiosInstance.patch(`api/v1/dclt/rental_contracts_2/${id}/user_plot/`, data, configAPI)
    },
  },
  plot: {
    createPlot(plotObj) {
      return AxiosInstance.post(`api/v1/apdln/x_plots_web/`, plotObj, configAPI)
    },
    updatePlot(plotObj, plotId) {
      return AxiosInstance.patch(`api/v1/apdln/x_plots_web/${plotId}/`, plotObj, configAPI)
    },
    uploadGpx(formData) {
      return AxiosInstance.post(`api/v1/apdln/x_features/view_gpx/`, formData, configAPI)
    },
  },
  warehouse: {
    getAll() {
      return AxiosInstance.get(`api/v1/apdln/x_warehouse_web/ `, configAPI)
    },
    create(data) {
      return AxiosInstance.post(`api/v1/apdln/x_warehouse_web/ `, data, configAPI)
    },
    update(id, data) {
      return AxiosInstance.patch(`api/v1/apdln/x_warehouse_web/${id}/ `, data, configAPI)
    },
    deleteById(id) {
      return AxiosInstance.delete(`api/v1/apdln/x_warehouse_web/${id}/`, configAPI)
    },
  },
  payment: {
    sendPaymentSum(data) {
      return AxiosInstance.post(`api/v1/payments/up_wallet/`, data, configAPI)
    },
  },
  tariff: {
    getBill(data) {
      return AxiosInstance.post(`api/v1/document_generation/bill/`, data, {...configAPI, responseType: "blob"})
    },
    changeTariff(data) {
      return AxiosInstance.post(`api/v1/organizations/change_tariff/`, data, configAPI)
    },
    getNewTariffPeriod(data) {
      return AxiosInstance.post(`api/v1/organizations/get_new_paid_to/`, data, configAPI)
    },
    getNewTariffRules(tariffId) {
      return AxiosInstance.get(`api/v1/tariff/${tariffId}/`, configAPI)
    },
  },
  feedback: {
    sendFeedbackMessage(data) {
      return AxiosInstance.post(`api/v1/users/feedback/`, data, configAPI)
    },
  },
  planetable: {
    getPlanetables() {
      return AxiosInstance.get(`api/v1/apdln/x_planetables/`, configAPI)
    },
    getPlanetable(id) {
      return AxiosInstance.get(`api/v1/apdln/x_planetables/${id}/`, configAPI)
    },
    uploadPlanetable(formData) {
      return AxiosInstance.post(`api/v1/apdln/x_planetables/`, formData, configAPI)
    },
    patchPlanetable(data) {
      return AxiosInstance.patch(`api/v1/apdln/x_planetables/${data.id}/`, data, configAPI)
    },
    deletePlanetable(planentableId) {
      return AxiosInstance.delete(`api/v1/apdln/x_planetables/${planentableId}/`, configAPI)
    },
    /*saveQuarter(data) {
      return AxiosInstance.post(`api/v1/apdln/x_quarters_on_planetables/`, data, configAPI)
    },*/
    saveQuartersLayer(data) {
      return AxiosInstance.post(`api/v1/apdln/x_quarters_on_planetables/multiple/`, data, configAPI)
    },
    deleteQuartersLayer(planetableId) {
      return AxiosInstance.delete(`api/v1/apdln/x_quarters_on_planetables/${planetableId}/delete_by_id_planetable/`, configAPI)
    },

    /*updateQuarter(data) {
      return AxiosInstance.patch(`api/v1/apdln/x_quarters_on_planetables/${data.id}/`, data, configAPI)
    },*/
    patchQuartersList(data) {
      return AxiosInstance.patch(`api/v1/apdln/x_quarters_on_planetables/multiple/`, data, configAPI)
    },
    /*deleteQuarter(id) {
      return AxiosInstance.delete(`api/v1/apdln/x_quarters_on_planetables/${id}/`, configAPI)
    },
    saveAllotment(data) {
      return AxiosInstance.post(`api/v1/apdln/x_allotments_on_planetables/`, data, configAPI)
    },
    patchAllotmentsList(data) {
      return AxiosInstance.patch(`api/v1/apdln/x_allotments_on_planetables/multiple/`, data, configAPI)
    },
    updateAllotment(data) {
      return AxiosInstance.patch(`api/v1/apdln/x_allotments_on_planetables/${data.id}/`, data, configAPI)
    },
    deleteAllotment(id) {
      return AxiosInstance.delete(`api/v1/apdln/x_allotments_on_planetables/${id}/`, configAPI)
    },
    savePillar(data) {
      return AxiosInstance.post(`api/v1/apdln/x_pillars_on_planetables/`, data, configAPI)
    },
    updatePillar(data) {
      return AxiosInstance.patch(`api/v1/apdln/x_pillars_on_planetables/${data.id}/`, data, configAPI)
    },
    deletePillar(id) {
      return AxiosInstance.delete(`api/v1/apdln/x_pillars_on_planetables/${id}/`, configAPI)
    },*/
    getQuartersFromMap(data, planetableId) {
      return AxiosInstance.post(`api/v1/apdln/x_planetables/${planetableId}/add_quarters_from_reference/`, data, configAPI)
    },
    /*updateArea(areaSvg, areaPxl, planetableId) {
      return AxiosInstance.post(`api/v1/apdln/x_planetables/${planetableId}/area_pxl/`,
        {area_pxl: areaSvg, area_pxl_original: areaPxl}, configAPI)
    },*/
  },
  reportUseForest: {
    getReportUseForestData(json) {
      return AxiosInstance.post('api/v1/dclt/polygon_to_report_use_forest/', json, configAPI)
    },
    sendReportUseForestData(data) {
      return AxiosInstance.post('api/v1/dclt/report_use_forest_2/', data, {...configAPI, responseType: 'blob'},)
    },
    getReportUseForestImage(url) {
      return AxiosInstance.get(url, {...configAPI, responseType: 'blob'},)
    },
  },
  stockpiles: {
    getPlotsStockpiles() {
      return AxiosInstance.get('api/v1/apdln/x_plots_stockpiles/', configAPI)
    },
    patchPlotsStockpile(plotId, data) {
      return AxiosInstance.patch(`api/v1/apdln/x_plots_stockpiles/${plotId}/`, data, configAPI)
    },
    getStockpile(stockpileId) {
      return AxiosInstance.get(`api/v1/stockpile_ph/stockpiles/${stockpileId}`, configAPI)
    },
    getStockpilePopupInfo(stockpileId) {
      return AxiosInstance.get(`api/v1/stockpile_ph/stockpiles/${stockpileId}/brief_data/`, configAPI)
    },
    createStockpile(data) {
      return AxiosInstance.post(`api/v1/stockpile_ph/stockpiles/`, data, configAPI)
    },
    patchStockpile(stockpileId, data) {
      return AxiosInstance.patch(`api/v1/stockpile_ph/stockpiles/${stockpileId}`, data, configAPI)
    },
    deleteStockpile(stockpileId) {
      return AxiosInstance.delete(`api/v1/stockpile_ph/stockpiles/${stockpileId}`, configAPI)
    },
    savePhotos(data) {
      return AxiosInstance.post(`api/v1/stockpile_ph/add_images/`, data, configAPI)
    },
    deletePhoto(photoId) {
      return AxiosInstance.delete(`api/v1/stockpile_ph/image/${photoId}/`, configAPI)
    },
    deleteAllPhotos(stockpileId) {
      return AxiosInstance.delete(`api/v1/stockpile_ph/stockpiles/${stockpileId}/delete_all_images/`, configAPI)
    },
  },
  stockpilePhoto: {
    saveFigure(figureObj) {
      return AxiosInstance.post('api/v1/stockpile_ph/figures/', figureObj, configAPI)
    },
    patchFigure(figureObj) {
      return AxiosInstance.patch(`api/v1/stockpile_ph/figures/${figureObj.id}/`, figureObj, configAPI)
    },
    cutFigure(data) {
      return AxiosInstance.post(`api/v1/stockpile_ph/figures/cut_figure/`, data, configAPI)
    },
    deleteFigure(figureID) {
      return AxiosInstance.delete('api/v1/stockpile_ph/figures/' + figureID + '/', configAPI)
    },
    recognizePhoto(photoId) {
      return AxiosInstance.get(`api/v1/stockpile_ph/image/${photoId}/recognition`, configAPI)
    },
    /*patchPhoto(photoData) {
      return AxiosInstance.patch(`api/v1/stockpile_ph/image/${photoData.id}/`, photoData, configAPI)
    },*/
    saveRuler(photoId, rulerData) {
      return AxiosInstance.post(`api/v1/stockpile_ph/image/${photoId}/change_ruler/`, rulerData, configAPI)
    },
  },
  operationalReport: {
    machines: {
      getMachines() {
        return AxiosInstance.get(`api/v1/operational_report/machine/`, configAPI)
      },
      createMachine(machineData) {
        return AxiosInstance.post(`api/v1/operational_report/machine/`, machineData, configAPI)
      },
      updateMachine(machineData) {
        return AxiosInstance.patch(`api/v1/operational_report/machine/${machineData.id}`, machineData, configAPI)
      },
      deleteMachine(machineId) {
        return AxiosInstance.delete(`api/v1/operational_report/machine/${machineId}`, configAPI)
      },
    },
    harvesting: {
      getHarvestingData() {
        return AxiosInstance.get(`api/v1/operational_report/harvesting/?limit=50`, configAPI)
      },
      getMoreHarvestingData(path) {
        return AxiosInstance.get(path, configAPI)
      },
      getFilteredHarvestingData(paramsStr) {
        return AxiosInstance.get(`api/v1/operational_report/harvesting/?limit=50&${paramsStr}`, configAPI)
      },
      saveHarvestingData(data) {
        return AxiosInstance.post(`api/v1/operational_report/harvesting/`, data, configAPI)
      },
      patchHarvestingData(data) {
        return AxiosInstance.patch(`api/v1/operational_report/harvesting/${data.id}/`, data, configAPI)
      },
      deleteHarvestingData(rowId) {
        return AxiosInstance.delete(`api/v1/operational_report/harvesting/${rowId}`, configAPI)
      },
    },
    removal: {
      getRemovalData() {
        return AxiosInstance.get(`api/v1/operational_report/removal/?limit=50`, configAPI)
      },
      getMoreRemovalData(path) {
        return AxiosInstance.get(path, configAPI)
      },
      getFilteredRemovalData(paramsStr) {
        return AxiosInstance.get(`api/v1/operational_report/removal/?limit=50&${paramsStr}`, configAPI)
      },
      saveRemovalData(data) {
        return AxiosInstance.post(`api/v1/operational_report/removal/`, data, configAPI)
      },
      patchRemovalData(data) {
        return AxiosInstance.patch(`api/v1/operational_report/removal/${data.id}/`, data, configAPI)
      },
      deleteRemovalData(rowId) {
        return AxiosInstance.delete(`api/v1/operational_report/removal/${rowId}`, configAPI)
      },
    },
    getOperationalReportPlots() {
      return AxiosInstance.get(`api/v1/operational_report/plot/`, configAPI)
    },
    getOperationalReportStores() {
      return AxiosInstance.get(`api/v1/operational_report/store/`, configAPI)
    }
  },
  rightPanel: {
    shapes: {
      getShapeData(shapeId) {
        return AxiosInstance.get(`api/v1/apdln/x_features/${shapeId}/panel/`, configAPI)
      },
      saveShapeComment(text, shapeId) {
        return AxiosInstance.post(`api/v1/apdln/x_features/${shapeId}/comment/`, {text}, configAPI)
      },
      saveShapePhotos(data, shapeId) {
        return AxiosInstance.post(`api/v1/apdln/x_features/${shapeId}/add_photos/`, data, configAPI)
      },
      deleteShapePhoto(data, shapeId) {
        return AxiosInstance.post(`api/v1/apdln/x_features/${shapeId}/delete_photo/`, data, configAPI)
      },
    },
    roads: {
      getRoadData(roadId) {
        return AxiosInstance.get(`api/v1/apdln/x_roads_web/${roadId}/panel`, configAPI)
      },
      saveRoadComment(text, roadId) {
        return AxiosInstance.post(`api/v1/apdln/x_roads_web/${roadId}/comment/`, {text}, configAPI)
      },
      saveRoadPhotos(data, roadId) {
        return AxiosInstance.post(`api/v1/apdln/x_roads_web/${roadId}/add_photos/`, data, configAPI)
      },
      deleteRoadPhoto(data, roadId) {
        return AxiosInstance.post(`api/v1/apdln/x_roads_web/${roadId}/delete_photo/`, data, configAPI)
      },
      patchRoadData(data, roadId) {
        return AxiosInstance.patch(`api/v1/apdln/x_roads_web/${roadId}`, data, configAPI)
      }
    },
    pillars: {
      getPillarData(pillarId) {
        return AxiosInstance.get(`api/v1/apdln/x_pillars_web/${pillarId}/panel`, configAPI)
      },
      savePillarComment(text, pillarId) {
        return AxiosInstance.post(`api/v1/apdln/x_pillars_web/${pillarId}/comment/`, {text}, configAPI)
      },
/*      savePillarPhotos(data, pillarId) {
        return AxiosInstance.post(`api/v1/apdln/x_pillars_web/${pillarId}/add_photos/`, data, configAPI)
      },
      deletePillarPhoto(data, pillarId) {
        return AxiosInstance.post(`api/v1/apdln/x_pillars_web/${pillarId}/delete_photo/`, data, configAPI)
      },*/
    },
    plots: {
      getPlotData(plotId) {
        return AxiosInstance.get(`api/v1/apdln/x_plots_web/${plotId}/panel/`, configAPI)
      },
      savePlotComment(text, plotId) {
        return AxiosInstance.post(`api/v1/apdln/x_plots_web/${plotId}/comment/`, {text}, configAPI)
      },
      savePlotPhotos(data, plotId) {
        return AxiosInstance.post(`api/v1/apdln/x_plots_web/${plotId}/add_photos/`, data, configAPI)
      },
      deletePlotPhoto(data, plotId) {
        return AxiosInstance.post(`api/v1/apdln/x_plots_web/${plotId}/delete_photo/`, data, configAPI)
      },
    },
    violations: {
      getById(id) {
        return AxiosInstance.get(`api/v1/apdln/x_violations/${id}/`, configAPI);
      },
      insertDescription(description, violationId) {
        return AxiosInstance.patch(`api/v1/apdln/x_violations/${violationId}/`, {description}, configAPI)
      },
      insertStatus(status, violationId) {
        return AxiosInstance.patch(`api/v1/apdln/x_violations/${violationId}/`, {status}, configAPI)
      },
      insertPriority(priority, violationId) {
        return AxiosInstance.patch(`api/v1/apdln/x_violations/${violationId}/`, {priority}, configAPI)
      },
      insertPhotos(data, violationId) {
        return AxiosInstance.post(`api/v1/apdln/x_violations/${violationId}/add_photos/`, data, configAPI)
      },
      deletePhotoById(data, violationId) {
        return AxiosInstance.post(`api/v1/apdln/x_violations/${violationId}/delete_photo/`, data, configAPI)
      },
    },
    warehouse: {
      getById(id) {
        return AxiosInstance.get(`api/v1/apdln/x_warehouse_web/${id}/panel/`, configAPI);
      },
      update(id, data) {
        return AxiosInstance.patch(`api/v1/apdln/x_warehouse_web/${id}/`, data, configAPI);
      },
      insertDescription(text, id) {
        return AxiosInstance.patch(`api/v1/apdln/x_warehouse_web/${id}/comment`, {text}, configAPI)
      },
    }
  },
  violations: {
    getAll() {
      return AxiosInstance.get('api/v1/apdln/x_violations/', configAPI)
    },
    deleteById(id) {
      return AxiosInstance.delete(`api/v1/apdln/x_violations/${id}/`, configAPI);
    },
  },
  map: {
    popup: {
      plots: {
        getVolumesBySpeciesAndProductName(id, dateStart = 0, dateFinish = 0) {
          const dates = getTMDatesURLPart(dateStart, dateFinish)
          return AxiosInstance.get(`apiv2/plot/volumes_by_species_and_product_name/${id}${dates}`, configAPI);
        },
        getVolumesBySpecies(id, dateStart = 0, dateFinish = 0) {
          const dates = getTMDatesURLPart(dateStart, dateFinish)
          return AxiosInstance.get(`apiv2/plot/volumes_by_species/${id}${dates}`, configAPI);
        },
        getVolumesByProductName(id, dateStart = 0, dateFinish = 0) {
          const dates = getTMDatesURLPart(dateStart, dateFinish)
          return AxiosInstance.get(`apiv2/plot/volumes_by_product_name/${id}${dates}`, configAPI);
        },
      }
    }
  }
}

// эндпоинты Сергея
export const dataAPI = {
  workspaces: {
    create(data) {
      return AxiosInstance.post(
        "api/v1/dclt/rental_contracts_2/", data,
        configAPI
      );
    },
    patch(data, contractId) {
      return AxiosInstance.patch(
        `api/v1/dclt/rental_contracts_2/${contractId}/`, data,
        configAPI
      );
    },
    delete(contractId) {
      return AxiosInstance.delete(
        `api/v1/dclt/rental_contracts_2/${contractId}/`,
        configAPI
      );
    },
    getLeaseBoundary(id) {
      return AxiosInstance.get(
        `api/v1/dclt/rental_contracts_2_web/${id}/lease_boundary/`,
        configAPI,
      )
    },
    saveLeaseBoundary(id, json) {
      return AxiosInstance.post(`api/v1/dclt/rental_contracts_2_web/${id}/lease_boundary/`, json, configAPI)
    },
    deleteLeaseBoundary(id) {
      return AxiosInstance.delete(`api/v1/dclt/rental_contracts_2_web/${id}/lease_boundary/`, configAPI)
    },
    changeLeaseBoundaryStatus(id, json) {
      return AxiosInstance.patch(`api/v1/dclt/rental_contracts_2_web/${id}/change_perimeter_rc_visible_web/`, json, configAPI)
    },

    settings: {
      firesNotification: {
        getAll(id) {
          return AxiosInstance.get(`api/v1/dclt/rental_contracts_2_web/${id}/fire_notification/`, configAPI)
        },
        updateAll(id, data) {
          return AxiosInstance.post(`api/v1/dclt/rental_contracts_2_web/${id}/fire_notification/`, data, configAPI)
        }
      },
      transportMonitoringData: {
        updateTransportMonitoringData(id) {
          return AxiosInstance.get(`api/v2/transport_monitoring?rental_contracts=${id}`, configAPI)
        },
        resetTransportMonitoringData(id) {
          return AxiosInstance.post(`api/v2/transport_monitoring/delete_sync?rental_contracts=${id}`, configAPI)
        }
      }
    },
    /*getFiresNotification(id) {
      return AxiosInstance.get(`api/v1/dclt/rental_contracts_2_web/${id}/fire_notification/`, configAPI)
    },
    saveFiresNotification(id, json) {
      return AxiosInstance.post(`api/v1/dclt/rental_contracts_2_web/${id}/fire_notification/`, json, configAPI)
    },*/
  },
  projects: {
    getWialonRoadsData(data) {
      return AxiosInstance.post(`api/v1/apdln/x_roads/to_kml/`, data, configAPI)
    },
    getWialonPlotsData(data) {
      return AxiosInstance.post(`api/v1/apdln/x_plots_web/to_kml/`, data, configAPI)
    },
    getWialonWarehousesData(data) {
      return AxiosInstance.post(`api/v1/apdln/x_warehouse_web/to_kml/`, data, configAPI)
    },
    getAll() {
      return AxiosInstance.get('api/v1/dclt/rental_contracts_2_web/folders/', configAPI)
    },
    shapes: {
      createFeatureInCollection(feature_json) {
        return AxiosInstance.post('api/v1/apdln/x_features/', feature_json, configAPI)
      },
      createMultiShapesOnServer(json) {
        return AxiosInstance.post('api/v1/apdln/x_features/multiple/', json, configAPI)
      },
      patch(id, json) {
        return AxiosInstance.patch(`api/v1/apdln/x_features/${id}/`, json, configAPI)
      },
      delete(id) {
        return AxiosInstance.delete(`api/v1/apdln/x_features/${id}/`, configAPI)
      },
    },
    folders: {
      create(json) {
        return AxiosInstance.post('api/v1/apdln/gclt_folders/', json, configAPI)
      },
      delete(id) {
        return AxiosInstance.delete(`api/v1/apdln/gclt_folders/${id}/`, configAPI)
      },
      patch(id, json) {
        return AxiosInstance.patch(`api/v1/apdln/gclt_folders/${id}/`, json, configAPI)
      },
    },
  },
  plots: {
    getAll() {
      return AxiosInstance.get('api/v1/apdln/x_plots_web/?show_archive=false', configAPI)
    },
    getAllArchived() {
      return AxiosInstance.get('api/v1/apdln/x_plots_web/?show_archive=true', configAPI)
    },
    delete(id) {
      return AxiosInstance.delete(`api/v1/apdln/x_plots_web/${id}/`, configAPI)
    },
    getShortInfo(id) {
      return AxiosInstance.get(`api/v1/apdln/x_plots_web/${id}/`, configAPI)
    },
    getShortInfoMultiply(json) {
      return AxiosInstance.post(`api/v1/apdln/x_plots_web/properties_by_ids/`, json, configAPI)
    },
    patchCAData: function (id, json) {
      return AxiosInstance.patch(`api/v1/apdln/x_plots_web/${id}/properties/`, json, configAPI)
    },
    pathCoordinates(id, json) {
      return AxiosInstance.patch(`api/v1/apdln/x_plots_web/${id}/`, json, configAPI)
    },
    getCAsMonitoringData(orgID) {
      return AxiosInstance.get(`apiv2/plot/by_organization/${orgID}`, configAPI)
    },
    getDeclarationAvaibleWorkspaces() {
      return AxiosInstance.get('/api/v1/dclt/declaration_from_xml/get_rental_contract', configAPI)
    },
    createCAsFromDeclarationXML(data) {
      return AxiosInstance.post('/api/v1/dclt/declaration_from_xml/', data, configAPI)
    },
    /*getPlotStockpiles(id) { //ендпоинт вроде как ненужон, но может пригодится
      return AxiosInstance.get(`api/v1/stockpile_ph/geo_stockpiles/?id_x_plot=${id}`, configAPI)
    },*/
  },
  roads: {
    getAll() {
      //return AxiosInstance.get('api/v1/apdln/x_roads_web/')
      return AxiosInstance.get('/api/v1/apdln/x_roads_web/by-rental-contract/')
    },
    create(json) {
      return AxiosInstance.post(`api/v1/apdln/x_roads_web/`, json, configAPI)
    },
    delete(id) {
      return AxiosInstance.delete(`api/v1/apdln/x_roads_web/${id}/`, configAPI)
    },
    patch(id, json) {
      return AxiosInstance.patch(`api/v1/apdln/x_roads_web/${id}/`, json, configAPI)
    },
  },
  pillars: {
    create(json) {
      return AxiosInstance.post('api/v1/apdln/x_pillars_web/', json, configAPI)
    },
    getAll() {
      return AxiosInstance.get('api/v1/apdln/x_pillars_web/by-rental-contract/', configAPI)
    },
    delete(id) {
      return AxiosInstance.delete(`api/v1/apdln/x_pillars_web/${id}/`, configAPI)
    },
    patch(id, json) {
      return AxiosInstance.patch(`api/v1/apdln/x_pillars_web/${id}/`, json, configAPI)
    },
  },
  tiles: {
    getWMSTile(url) {
      return AxiosInstance.get(url, {...configAPI, responseType: 'blob'})
    },
    getFeatureInfo(url) {
      return AxiosInstance.get(url, configAPI)
    },
  },
  miscGis: {
    getQuartersByPoint(json) {
      return AxiosInstance.post('api/v1/apdln/x_quarters_web/by_point_distance/', json, configAPI)
    },
    getQuartersByIDs(json) {
      return AxiosInstance.post(`api/v1/apdln/x_quarters_web/by_ids/`, json, configAPI)
    },
    testGPXFile(formData) {
      return AxiosInstance.post(`api/v1/apdln/x_features/test_gpx_file/`, formData, configAPI)
    },
  },
  userLayers: {
    create(json) {
      return AxiosInstance.post('api/v1/apdln/map_layer/', json, configAPI)
    },
    getAll() {
      return AxiosInstance.get('api/v1/apdln/map_layer/', configAPI)
    },
    patch(id, json) {
      return AxiosInstance.patch(`api/v1/apdln/map_layer/${id}/`, json, configAPI)
    },
    delete(id) {
      return AxiosInstance.delete(`api/v1/apdln/map_layer/${id}/`, configAPI)
    },
  },
  reports: {
    ca: {
      commonCAReport(id) {
        return AxiosInstance.get(`api/v1/apdln/x_plot_report/${id}`, {...configAPI, responseType: 'blob'})
      }
    }
  },
  searching: {
    cas(query, signal, limit = 10) {
      return AxiosInstance.get(`/api/v1/search/search_xplot?q=${query}&limit=${limit}`, {...configAPI, signal: signal})
    },
    kv(query, signal, limit = 10) {
      return AxiosInstance.get(`/api/v1/search/search_xquarter?q=${query}&limit=${limit}`, {...configAPI, signal: signal})
    },
    geo(query, signal, limit = 10) {
      return AxiosInstance.get(`/api/v1/search/search_geo?q=${query}&limit=${limit}`, {...configAPI, signal: signal})
    },
    getCoordinates(layer, id) {
      return AxiosInstance.get(`/api/v1/gis/get_latlon/?layer=${layer}&id=${id}`, configAPI)
    },
  },
}
