import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography,} from "@mui/material";
import {getAllAllowedRentalContractRoadsThunk, getAllRentalContract, setRentalContractsAccessState} from "../../../../../../../../redux/reducers/organizationInfoReducer";
import './TableRowStyles.css';
import {Loading} from "../../../../../../../Main/Map/Common/Loading";

const headers = [
    "",
    "Договор аренды",
    "Обычные",
    "Вывозка"
];

const headerKeys = [
    "",
    "number",
    "show_ready_roads",
    "show_removal_roads",
];

/*const rowStyle = {
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#f5f5f5', // Или любой другой цвет, который вы предпочитаете
    }
};*/

const HeaderCell = ({header, onHeaderChange, allChecked}) => {
    return (
        <TableCell>
            {header === "Договор аренды" || header === "" ? header : (
                <label>
                    <Checkbox
                        checked={allChecked}
                        onChange={() => onHeaderChange(header)}
                    />
                    {header}
                </label>
            )}
        </TableCell>
    );
};

const ThirdTabAccessRoads = ({user}) => {
    const dispatch = useDispatch();
    const rentalContractsRoads = useSelector(state => state.organizationInfoReducer.allRentalContract.rental_contracts_roads);
    const isLoading = useSelector(state => state.organizationInfoReducer.isLoading);
    const [tableData, setTableData] = useState([]);
    const isRowChecked = (row) => {
        // Проверяем, что хотя бы один чекбокс в строке выбран
        return headerKeys.slice(2).some(key => row[key]); // Начинаем с третьего ключа, пропуская пустую строку и 'number'
    };

    useEffect(() => {
        dispatch(getAllAllowedRentalContractRoadsThunk(user.id));
    }, [dispatch, user.id]);

    useEffect(() => {
        setTableData(rentalContractsRoads || []);
    }, [rentalContractsRoads]);

    const handleRowCheckboxChange = (rowIndex) => {
        const row = tableData[rowIndex];
        const allChecked = !headerKeys.slice(2).every(key => row[key]); // начните с 3-го элемента, пропуская пустую строку и 'number'
        const updatedData = tableData.map((r, i) =>
            i === rowIndex
                ? {
                    ...r,
                    ...headerKeys.slice(2).reduce((acc, key) => { // начните с 3-го элемента
                        acc[key] = allChecked;
                        return acc;
                    }, {})
                }
                : r
        );

        setTableData(updatedData);
        dispatch(getAllRentalContract({ rental_contracts_roads: updatedData }));
        dispatch(setRentalContractsAccessState('roads', true));
    };





    const handleHeaderChange = (header) => {
        const key = headerKeys[headers.indexOf(header)];
        const allChecked = !tableData.every(row => row[key]);
        const updatedData = tableData.map(row => ({
            ...row,
            [key]: allChecked
        }));
        setTableData(updatedData);

        dispatch(getAllRentalContract({rental_contracts_roads: updatedData}))
        dispatch(setRentalContractsAccessState('roads', true));
    };

    const handleCheckboxChange = (rowIndex, columnIndex) => {
        const key = headerKeys[columnIndex];
        const updatedData = tableData.map((row, i) => i === rowIndex ? {
            ...row,
            [key]: !row[key]
        } : row);
        setTableData(updatedData);

        // Получение ID при изменении чекбокса
        //const id = tableData[rowIndex].id;
        dispatch(getAllRentalContract({rental_contracts_roads: updatedData}))
        dispatch(setRentalContractsAccessState('roads', true));
    };

    return <>
        {isLoading ? <Loading/> :
            rentalContractsRoads?.length > 0 ?
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                <HeaderCell
                                    key={index}
                                    header={header}
                                    onHeaderChange={handleHeaderChange}
                                    allChecked={tableData.length > 0 && tableData.some(row => row[headerKeys[index]])}
                                />
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((row, rowIndex) => (
                            <TableRow key={rowIndex} className="hoverTableRow">
                                <TableCell>
                                    <Checkbox
                                        checked={isRowChecked(row)}
                                        onChange={() => handleRowCheckboxChange(rowIndex)}
                                    />
                                </TableCell>
                                {headerKeys.slice(1).map((key, cellIndex) => (
                                    <TableCell key={cellIndex}>
                                        {cellIndex === 0 ? row[key] : (
                                            <Checkbox
                                                checked={row[key]}
                                                onChange={() => handleCheckboxChange(rowIndex, cellIndex + 1)}
                                            />
                                        )}
                                    </TableCell>
                                ))}

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                : (<Typography>Договоры аренды отсутствуют</Typography>)}
    </>
};

export default ThirdTabAccessRoads;
