//COMMON SHORT
export const loadingString = 'Загрузка...';
export const clearString = 'Очистить';
export const closeString = 'Закрыть';
export const notFoundString = 'Не найдено';
export const increaseString = 'Увеличить';
export const decreaseString = 'Уменьшить';
export const rebootString = 'Перезагрузить';
export const renameString = 'Переименовать';
export const deleteString = 'Удалить';
export const errorString = 'Ошибка';
export const changeString = 'Изменить';
export const okText = 'ОК';
export const cancelText = 'Отмена';
export const millimetersShortString = 'мм';
export const createString = 'Создать';
export const saveString = 'Сохранить';
export const lineString = 'Линия';
export const polygonString = 'Полигон';
export const markerString = 'Маркер';
//export const infoButtonTittleString = 'Описание функционала';
export const grouppingString = 'Группировка'
export const parameterString = 'Параметр';
export const valueString = 'Значение';
export const squareMeterString = 'м²';
export const cubicMeterString = 'м³';
export const hectareShortString = 'га';
export const meterCubicHectareString = 'м³/га';
//export const shapesString = 'Фигуры';
export const casString = 'Деляны';
//export const roadsString = 'Дороги';
//export const pillarsString = 'Столбы';
export const typeString = 'Тип';
export const urlString = 'URL';
export const addString = 'Добавить';
export const editString = 'Редактировать';
export const declarationString = 'Декларация';
//export const name2String = 'Наименование';
export const caString = 'Деляна';
export const listString = 'Список';
export const filtersString = "Фильтры";
export const caSquareString = 'Площадь';
export const moreString = 'Подробно';
export const applyString = 'Применить';
export const transparentString = 'Прозрачность';
export const leftPanelString = 'Левая';
export const rightPanelString = 'Правая';
export const emptyString = 'Пусто';
export const settingsString = 'Настройки';
export const warningString = 'Предупреждение';
//export const addresseeString = 'Адресат';
export const warnind2String = 'Внимание!';
export const zoomMinString = 'Минимальный зум';
export const zoomMaxString = 'Максимальный зум';
export const latitudeString = 'Широта';
export const longitudeString = 'Долгота';
export const coordinatesString = 'Координаты';
export const repeatString = 'Повторить';

//COMMON
export const savingSuccess = 'Cохранение успешно завершено.';
export const savingError = 'Ошибка при сохранении.';
export const deletingSuccess = 'Удаление успешно завершено.';
export const deletingError = 'Удаление завершилось с ошибкой.';
export const componentIsNotRealized = 'Компонент не реализован.';
export const functionalIsNotRelized = 'Функционал не реализован в этой версии.';
export const saveTargetNotFound = 'Не удалось определить цель для сохранения.';
export const tariffExpiredLong = 'Истек срок действия тарифа. Вы можете только просматривать информацию.';
export const errorOnDataRecieved = 'Ошибка при получении данных с сервера';
export const saveAsString = 'Сохранить как...';
export const saveChangeString = 'Сохранить изменения';
export const noInfoString = 'Нет информации';
export const tarifLimitExited = 'Превышено ограничение для вашего тарифного плана.';
//export const tooMuchMoreElements = '...Слишком много элементов...';
export const il1ReportString = 'Приложение к отчету ИЛ-1/ВЛ-1';
export const commonNetwokError = 'Произошла ошибка. Проверьте соединение с сетью и повторите действие еще раз.';
export const noNameString = 'Без названия';
export const deleteFromListString = 'Удалить из списка';
export const selectAreaString = 'Выделить произвольную область';
export const cleanListString = 'Очистить список';
export const deletingErrorString = 'При удалении произошла ошибка.'
export const infoText = 'Справочная информация';
//export const employeeAlreadyAdded = 'Сотрудник ___1___ уже добавлен';
export const settingsSavedString = 'Изменения сохранены';
export const moreParametersString = 'Дополнительные параметры';
export const coordinatesEditorString = 'Редактирование координат';
export const errorInDataString = 'Обнаружены ошибки, проверьте введенные данные';
export const networkErrorString = 'Произошла ошибка. Проверьте подключение к интернету или попробуйте позже.';

// Warehouse
export const warehouseCreateString = 'Создать склад';
export const createWarehouseForbiddenString = 'Создание склада не возможно на тарифе "Лайт"';
export const createWarehouseForbiddenForUserTypeString = 'На вашем тарифном плане только администратор и владелец могут создавать склады';

//CAs
export const deletingCA = 'Удаление деляны';
export const confirmDeleteCA = 'Вы уверены, что хотите удалить эту деляну? Эта операция необратима.';
export const noCAsInSearchResults = 'Нет делян для отображения.';
export const casLoadingErrorString = 'Произошла ошибка при загрузке делян. Нажмите кнопку ниже, чтобы повторить загрузку.';
export const caCreatedString = 'Деляна создана';
export const caCreatedButNotVisibleString = 'Деляна создана, но не отображается, так как включен фильтр.';
export const grouppingSettingsString = "Настройки группировки";
export const getCAInfoError = "Произошла ошибка при получении сведений о деляне";
export const createCAForbiddenString = 'Создание деляны не возможно на тарифе "Лайт"';
export const createCAForbiddenForUserTypeString = 'На вашем тарифном плане только администратор и владелец могут создавать деляны';
export const createCAString = 'Создать деляну';
export const allCAsString = 'Показать все деляны';
export const allCAsRemoveFilters = 'Убрать фильтры';
export const casSelected = 'Выбранные деляны';
export const casSelectionNote = 'Зажмите ctrl и щелкните по нужным делянам на карте или воспользуйтесь кнопками выше.';
export const caStatusChangeString = 'Внести данные о деляне';
export const ca1CBlockingEditingString = 'Данные получены из 1С.';
export const caCommonInfoEditString = 'Редактировать общую информацию о деляне';
//export const caPathErrorString = 'При изменении деляны произошла ошибка';
export const caPathCoordinatesIsOKString = 'Координаты деляны упешно изменены';
export const caPencilSelect = 'Выбрать деляны';

//CAS properties
export const caInfoString = 'Информация о деляне';
export const allotmentsString = 'Выделы';
export const districtForestryName = 'Уч. лесничество';
export const forestryString = 'Лесничество';
export const caNameString = 'Имя';
export const caNumberString = 'Номер деляны';
export const caOwnerName = 'Владелец';
export const caQuarterNumberString = 'Номер квартала';
export const caStatusString = 'Статус';
export const caRegionString = 'Регион';
export const caWorkStartString = 'Начало разработки';
export const caResidualVolumeString = 'Объем остаток';
export const caExportVolumeString = 'Объем по вывозке';
export const caDeclarationVolumeString = 'Объем по декларации';
export const caHarvestVolumeString = 'Объем по заготовке';
export const caMeasurementVolumeString = 'Объем по замерам';
export const caWorkEndString = 'Окончание разработки';
export const caCreatorString = 'Отвел';
export const caValueString = 'Показатели м³/га';
export const caValuePercentString = 'Показатели %';
export const caLastTeamString = 'Последняя бригада';
export const caLastComplexNameString = 'Аббревиатура техники';
export const caExportPercentString = 'Процент вывозки';
export const caCommonInfo = 'Общие сведения';
export const ca1CInfoString = 'Данные учета';
export const maxiFleetInfoString = 'Данные мониторинга транспорта';
export const caNo1Cdata = 'Нет данных из системы учета';
//export const caNoMaxiFleetdata = 'Нет данных из системы мониторинга';
export const caPlanVolumeString = 'План вырубки';
export const consolidatedReportString = 'Сводный отчет';

//Filters
export const clearAllFiltersString = 'Очистить все фильтры';

//Dialogs
//export const enterName = 'Введите название';
export const nameString = 'Название';
export const nameIsEmptyError = 'Название не может быть пустым.';
export const urlIsEmptyErrorString = 'URL не может быть пустым';

//Projects Context Menu
export const saveGPXTrackString = 'Сохранить GPX трек';
export const saveGeoJSONString = 'Сохранить GeoJSON';

//Roads
export const roadsDeleting = 'Удаление дороги';
export const roadsDeletingConfirmation = 'Вы уверены, что хотите удалить эту дорогу? Эта операция необратима.';
//export const roadCreatingErrorString = 'При создании дороги произошла ошибка.';
//export const roadRenameErrorString = 'При изменении дороги произошла ошибка.';
//export const roadsLoadingErrorString = 'Произошла ошибка при загрузке дорог. Нажмите кнопку ниже, чтобы попробовать еще раз.'
//export const roadsReloadButtonString = 'Перезагрузить дороги';
export const roadDeleteTitleString = 'Удалить дорогу';
export const roadEditTitleString = 'Редактировать дорогу';
export const roadHideTitleString = 'Скрыть дорогу';

//Workspaces
export const workspaceString = 'Рабочая область';
export const creatingWorkspaceIsOK = 'Рабочая область создана';
//export const creatingWorkspaceIsFail = 'При создании рабочей области произошла ошибка';
export const deleteWorkspaceString = 'Удаление рабочей области';
export const deleteWorkspaceText = 'Удаление рабочей области так же удалит все привязанные к ней объекты (фигуры, деляны, дороги и т.п.). Вы уверены?';
//export const deleteWorkspaceError = 'При удалении рабочей области произошла ошибка.';
//export const renameWorkspaceError = 'При переименовании рабочей области произошла ошибка';
export const createWorkspaceString = 'Создать рабочую область';
export const createWialonDataString = "Создать выгрузку для Wialon";
export const deleteDistrictForestryString = 'Удаление участкового лесничества';
export const deleteDistrictForestryText = 'Вы уверены, что хотите удалить участковое лесничество "__1__"?';
export const districtForestryAlreadyAdded = 'Участковое лесничество "__1__" уже добавлено';
export const districtForestryChangeWarningString = 'При смене участкового лесничества будет очищен список кварталов. Вы уверены?';
export const forestryChangeWarningString = 'При смене лесничества будет очищен список участковых лесничеств. Вы уверены?';
export const districtChangeWarningString = 'При смене региона будет очищена вся информация по лесничествам и кварталам. Вы уверены?';
export const quartersNumbersSquenceFailedString = 'Введите строку в формате: "1,2,3-10,25,27-30"';
export const quartersNumbersClearString = 'Список кварталов будет очищен. Вы уверены?';
export const workspaceProperiesSaveNoForestryString = 'Лесничество не указано.';
export const workspaceProperiesSaveNoQuartersString = 'Кварталы не указаны.';
export const workspaceRentBorders = 'Границы аренды';
export const firesNotifications = 'Уведомление о пожарах';
export const firesNotificationRadius = 'Радиус оповещения (м)';
export const firesNotificationTime = 'Частота оповещений (раз/сутки)';
//export const firesNotificationNoRadius = 'Задайте радиус оповещения в километрах';
//export const firesNotificationNoAddressee = 'Адресаты не добавлены';
//export const firesNotificationNoAdresseeFlags = 'Для адресата ___1___ не установлены типы уведомлений.';
//export const firesNotificationDeleteAddresseeString = 'Удалить этого аресата?';
export const firesNotificationEnableNotificationString = 'Включить уведомления';
export const firesNotificationRentSavingError = 'Произошла ошибка при сохранении границ аренды';
//export const firesNotificationFilresSavingError = 'Произошла ошибка при сохранении настроек уведомлений';
export const workspaceSettingSaved = 'Изменения рабочей области сохранены';

//Folders
export const deleteFolderString = 'Удаление папки';
export const deleteFolderTest = 'Удаление папки повлечет так же удаление всех вложенных объектов (папок, фигур). Вы уверены?';
//export const renameFolderErrorString = 'Произошла ошибка при переименовании папки';
//export const createFolderErrorString = 'Произошла ошибка при создании папки.';

//Shapes
export const deleteShapeString = 'Удаление фигуры';
export const deleteShapeText = 'Вы уверены, что хотите удалить фигуру "__SHAPENAME__"';
//export const shapeSavingError = 'Произошла ошибка при сохранеии фигуры.';

//Quarters
export const quartersString = 'Кварталы';
export const quartersLoadingErrorString = 'Произошла ошибка при получении списка кварталов';
export const quarterSearchRadius = "Радиус поиска кварталов (м)";

//Pillars
//export const pillarCreatingErrorString = 'Произошла ошибка при создании столба.';
export const pillarRemovingString = 'Удаление столба';
//export const pillarPatchErrorString = 'Произошла ошибка при изменении столба.';
export const pillarDeletingConfirmation = 'Вы уверены, что хотите удалить этот столб? Эта операция необратима.';
export const pillarCreatingString = 'Создание квартального столба';
export const pillarPatchingString = 'Изменение квартального столба';
export const pillarsLoadingErrorString = 'Произошла ошибка при загрузке столбов. Нажмите кнопку ниже, чтобы попробовать еще раз.';
export const pillarsReloadButtonString = 'Перезагрузить столбы';
export const pillarDeleteString = 'Удалить столб';
export const pillarHideString = 'Скрыть столб';

//GPX File
//export const gpxMultipoinCreatingError = 'Произошла ошибка при создании точек на сервере';
export const fileProcessingErrorString = 'Ошибка при обработке файла.';
export const saveCooordinatesAs = 'Сохранить координаты';

//Base Layers
export const baseLayersTitleString = 'Базовые слои';
export const sentinelLayerForbiddenString = 'Слой Sentinel недоступен';

//Measuring
export const measuringString = 'Измерения';
export const measuringAreaString = 'Измерение площади';
export const measuringDistanceString = 'Измерение расстояния';

//Blind
export const blindLayersCompareString = 'Режим сравнения слоев';
export const blindCloseString = 'Выйти из режима сравнения слоев';

//MiscLayers
export const miscLayersHeaderString = 'Общие';
export const miscUserLayersHeaderString = 'Слои пользователя';
export const miscUserLayerAddingString = 'Добавить слой';
export const miscUserLayerDialogHeaderString = 'Создание слоя';
export const miscUserLayersLoadingErrorString = 'Произошла ошибка при загрузке пользовательских слоев.  Нажмите кнопку ниже, чтобы повторить.';
export const miscUserLayersReloadButtonString = 'Перезагрузить слои';
export const deletingUserMiscLayerString = 'Удаление слоя';
export const deletingUserMiscLayerText = 'Вы уверены, что хотите удалить этот слой? Эта операция необратима.';
export const userLayerWithNameExists = 'Слой с таким именем уже существует.';
export const userMiscLayersDeleteErrorString = 'Произошла ошибка при удалении слоя.';
export const userMiscLayerAddingForbiddenWithLite = 'Пользовательские слои недоступны на тарифах "Лайт" и "Базовый".';
export const userMiscLayerAddingForbidden = 'Создавать слои могут только пользователи со статусами "администратор" и "владелец."' ;
export const userMiscLayersViewForbidden = 'Просмотр пользовательских слоев недоступен на тарифах "Лайт" и "Базовый"';
export const userMiscLayersCountLimitString = 'Превышено ограничение: 5 слоев на организацию.';
export const miscLayerSwitchStyle = 'Переключить стиль';
export const miscLayerHaveNoStyles = 'Данный слой не имеет стилей.';
export const userLayerGetFeatureInfoEnable = 'Показывать доп. информацию по клику на карте';
export const zoom20ErrorString = 'Зум должен быть числом от 0 до 20'
export const minMaxZoomErrorString = 'Минимальный зум должен быть меньше или равен максимальному'
//export const accuracyIncrease = 'Уменьшить точность';
export const cancelPencilDrawing = 'Отменить рисование';

//Projects
export const projectsLoadingError = 'Произошла ошибка при загрузке проектов. Нажмите кнопку ниже, чтобы повторить.';
export const projectCreateFolderString = 'Создать папку';
export const projectsRemoveLastButtonString = 'Удалить последнюю точку';
export const projectsLoadGPXString = 'Загрузить gpx';
export const topPanelBlock1String = 'Папки';
//export const topPanelBlock2String = 'По координатам';
//export const topPanelBlock3String = 'На карте';
export const topPanelBlock4String = 'Инструменты';
export const pencilSelectionString = 'Произвольное выделение';
export const createByCoordinates = 'Создать по координатам';
export const coordinatesChangeSuccess = 'Координаты изменены';

//Reports
//export const reportIL1String = 'Отчет ИЛ-1/ВЛ-1';
//export const reportString = 'Отчет';
export const reportPDFString = 'Отчет 1С';
export const reportIL1 = 'Отчет ИЛ-1/ВЛ-1';
export const reportIL1CreateReportString = 'Создать отчет';

//Printing
export const pageOrientationString = 'Ориентация';
export const pageOrientationPortraitString = 'Вертикальная';
export const pageOrientationLandscapeString = 'Горизонтальная';
export const pageSizeString = 'Размер страницы';
export const pageMarginStrings = 'Поля страницы';
export const pageShowShapesString = 'Показать фигуры';
export const pageShowCasLabelsString = 'Подписать деляны';
export const pageShowCasLabelsStratums = 'Показать выделы в подписях';
export const printingString = 'Печать';
export const printingNotAllLayerErrorString = 'Некоторые слои не были полностью загружены. Все равно распечатать?';

//Misc
export const myLocationString = 'Моё местоположение';

//Exit
export const ExitDialogTitle = 'Выход';
export const confirmExitMessage = 'Вы уверены что хотите выйти?';

//Planetables
export const plotStockpilePopupTitle = 'Информация о штабеле';
export const deletingPlanetable = 'Удаление планшета';
export const confirmDeletePlanetable = 'Вы уверены, что хотите удалить этот планшет? Эта операция необратима.';
//export const confirmDeleteStockpile = 'Вы уверены, что хотите удалить этот штабель? Эта операция необратима.';
export const deletingStockpile = 'Удаление штабеля';
//export const deleteQuarterDialogTitle = 'Удаление квартала';
//export const confirmDeleteQuarter = 'Вы уверены что хотите удалить выбранный квартал с планшета?';
export const planetablesViewForbiddenWithLiteString = 'Просмотр планшетов на тарифе "Лайт" недоступен.';
export const planetablesEditForbiddenWithLiteString = 'Редактор планшетов на тарифе "Лайт" недоступен.';

//LeftDrawer
export const leftDrawerCAUseForbidden = 'Список делян недоступен на тарифе "Лайт"';
export const leftDrawerPrintingUseForbidden = 'Печать недоступна на тарифе "Лайт"';
export const leftDrawerRoadsUseForbidden = 'Раздел логистики недоступен на тарифе "Лайт"';

//Mobile banner
export const mbannerText = 'Полный функционал веб-приложения доступен при работе с ПК.';
export const mbannerButtonText = 'Понятно, нужно зайти с ПК';
export const mbannerGoToPCString = 'Полный функционал веб-приложения доступен при работе с ПК.';
export const mbannerLinkText = 'Подробнее о работе веб-приложения';

//Jivo
export const jivoTittleString = 'Задать вопрос';

//Coordinates
export const replaceAllCoordinatesWarningString = 'Все координаты будут перезаписаны содержимым файла. Продолжить?';
//export const selfIntersectionWarningString = 'Обнаружены самопересечения. Исправьте ошибки и попробуйте еще раз';
export const coordinatesLessThen1String = 'Введите координаты точки';
export const coordinatesLessThen2String = 'Введите не меньше двух координат';
export const coordinatesLessThen3String = 'Введите не меньше трех координат';
export const coordinatesFitToBoundString = 'Подогнать к границам';
export const coordinatesIdenticalPointsDetectedString = 'Точки №___1___ и №___2___ имеют одинаковые координаты';
