import React, {useEffect, useState} from 'react';
import {IconButton, Stack, TableCell, TableRow, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import UsersInWorkspacesEditable from "./UsersInWorkspacesEditable";
import ButtonsBlock from "./ButtonsBlock";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {setOrgInfoAttr, updateWorkspaceThunk} from "../../../../../redux/reducers/organizationInfoReducer";
import TextField from "@mui/material/TextField";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {WorkspacePermissionsSettings} from "../WorkspacePermissionsSettings/WorkspacePermissionsSettings";

const ColumnComponent = ({columnName, workspace, editHandler, index, setOpen, iAmOwner}) => {
  switch (columnName) {
    case 'order':
      return index + 1
    case 'number':
      return (
        <Stack direction={'row'} alignItems={'center'}>
          <Typography>{workspace[columnName]}</Typography>
          <IconButton title={'Изменить имя'} onClick={editHandler}>
            <EditOutlinedIcon sx={{fontSize: 18}}/>
          </IconButton>
        </Stack>
      )
    case 'created_at':
      return workspace[columnName]
    case 'users':
      return <UsersInWorkspacesEditable workspace={workspace} iAmOwner={iAmOwner}/>
    case 'buttons':
      return <ButtonsBlock workspace={workspace} setOpen={setOpen}/>
  }
}

const WorkspaceRow = ({workspace, index, WorkSpacesTableColumns, iAmOwner}) => {
  const dispatch = useDispatch()
  const selectedWorkspace = useSelector(state => state.organizationInfoReducer.selectedWorkspace)
  const [workspaceName, setWorkspaceName] = useState('')
  const [openWPDialog, setOpenWPDialog] = useState(false)
  const isSelectedWorkspace = selectedWorkspace?.id === workspace?.id;

  useEffect(() => {
    if (isSelectedWorkspace) {
      setWorkspaceName(workspace.number)
      const workspaceNameInputEl = document.getElementById('workspaceNameInput')
      workspaceNameInputEl && workspaceNameInputEl.focus()
    }
  }, [isSelectedWorkspace])

  function changeWorkspaceNameHandler(name) {
    setWorkspaceName(name)
  }

  function undoEditWorkspace() {
    dispatch(setOrgInfoAttr({createWorkspaceMode: false, selectedWorkspace: null}))
  }

  function updateWorkspaceHandler() {
    dispatch(updateWorkspaceThunk(workspace.id, {number: workspaceName}))
  }

  const selectWorkspaceHandler = () => {
    dispatch(setOrgInfoAttr({selectedWorkspace: workspace, createWorkspaceMode: false}))
  }

  const onKeyDownHandler = (e) => {
    switch (e.code) {
      case 'Escape':
        undoEditWorkspace()
        break
      case 'Enter':
      case 'NumpadEnter':
        updateWorkspaceHandler()
        break
    }
  }

  function handleClose() {
    setOpenWPDialog(false)
  }

  return (
    <TableRow onKeyDown={onKeyDownHandler}>
      {openWPDialog && <WorkspacePermissionsSettings workspace={workspace} handleClose={handleClose}/>}
      {WorkSpacesTableColumns.map((column) =>
        <TableCell key={column.name} align="left">
          {column.name === 'number' && isSelectedWorkspace
            ?
              <Stack direction={'row'} alignItems={'center'}>
                <TextField
                  id={'workspaceNameInput'}
                  value={workspaceName}
                  variant={'standard'}
                  size={'small'}
                  inputProps={{maxLength: 50, style: {paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}}
                  onChange={(e) => changeWorkspaceNameHandler(e.target.value)}
                />
                <IconButton
                  onClick={updateWorkspaceHandler}>
                  <DoneIcon sx={{color: 'primary.dark', fontSize: 18}}/>
                </IconButton>
                <IconButton onClick={undoEditWorkspace}>
                  <CloseIcon sx={{color: '#A44646', fontSize: 18}}/>
                </IconButton>
              </Stack>
            :
              <ColumnComponent
                columnName={column.name}
                workspace={workspace}
                editHandler={selectWorkspaceHandler}
                index={index}
                setOpen={setOpenWPDialog}
                iAmOwner={iAmOwner}
              />
          }
        </TableCell>
      )}
    </TableRow>
  )
}

export default WorkspaceRow;
