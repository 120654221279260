import {dataAPI} from "../../../../../../api/api";
import {handleErrors} from "../../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {setSnack} from "../../../../Map/Common/Dialog/Snack/snackReducer";

const SET_WORKSPACE_ATTR = 'SET_WORKSPACE_ATTR'
const SET_FIRE_NOTIFICATION = 'SET_FIRE_NOTIFICATION'

export const newQuarterTemplate = {districtForestry: null, numbers: []}
export const nullRentItem = {region: null, forestry: null, quarters: [{...newQuarterTemplate}]}

const defaultState = {
  edit: false,
  open: false,
  node: null,
  loading: false,
  loaded: false,
  saving: false,
  loadingError: false,
  savingError: false,
  rentData: structuredClone(nullRentItem),
  firesData: null,
  altDistrictForestryWithQuartersList: [],
}

export function workspaceReducer(state = defaultState, action) {
  switch (action.type) {
    case 'MAP_OPEN_WORKSPACE_SETTINGS_DIALOG':
      return {...defaultState, open: true, edit: action.edit, node: action.node}
    case 'MAP_CLOSE_WORKSPACE_SETTINGS_DIALOG':
      return {...defaultState}
    case 'MAP_START_WORKSPACE_SETTINGS_LOADING':
      return {...state, loading: true, loaded: false, loadingError: null}
    case 'MAP_STOP_WORKSPACE_SETTINGS_LOADING':
      return {...state, loading: false, loaded: true, loadingError: action.error}
    case 'MAP_RESTART_WORKSPACE_SETTINGS_LOADING':
      return {...state, loading: false, loaded: false, loadingError: null}
    case 'MAP_START_WORKSPACE_SETTINGS_SAVING':
      return {...state, saving: true, savingError: null}
    case 'MAP_STOP_WORKSPACE_SETTINGS_SAVING':
      return {...state, savingError: action.error}
    case 'MAP_SET_WORKSPACE_RENT_DATA':
      return {...state, rentData: {...state.rentData, ...action.data}}
    case 'MAP_SET_WORKSPACE_ALL_DATA':
      return {...state, rentData: action.rentData}
    case SET_WORKSPACE_ATTR:
      return { ...state, ...action.payload}
    case SET_FIRE_NOTIFICATION:
      return {...state, firesData: {...state.firesData, ...action.data}}
    default:
      return state;
  }
}

export function startWPSLoading() {
  return {
    type: 'MAP_START_WORKSPACE_SETTINGS_LOADING',
  }
}

export function stopWPSLoading(error) {
  return {
    type: 'MAP_STOP_WORKSPACE_SETTINGS_LOADING',
    error: error,
  }
}

export function restartWPSLoading(error) {
  return {
    type: 'MAP_RESTART_WORKSPACE_SETTINGS_LOADING',
    error: error,
  }
}

export function openWorkspaceSettingsDialog(node, editMode) {
  return {
    type: 'MAP_OPEN_WORKSPACE_SETTINGS_DIALOG',
    node: node,
    edit: editMode,
  }
}

export function closeWorkspaceSettingsDialog() {
  return {
    type: 'MAP_CLOSE_WORKSPACE_SETTINGS_DIALOG',
  }
}

export function setWorkspaceAllData(rentData) {
  return {
    type: 'MAP_SET_WORKSPACE_ALL_DATA',
    rentData: rentData,
  }
}

export function setWorkspaceRentData(data) {
  return {
    type: 'MAP_SET_WORKSPACE_RENT_DATA',
    data: data,
  }
}

/*export function setWorkspaceFiresData(data) {
  return {
    type: 'MAP_SET_WORKSPACE_FIRES_DATA',
    data: data,
  }
}*/

export const setFireNotification = (data) => ({
    type: SET_FIRE_NOTIFICATION,
    data: data
});

export const setWorkspaceAttr = (payload) => ({
  type: SET_WORKSPACE_ATTR,
  payload
});

/*export const getAltDistrictForestryWithQuartersListThunk = (altDistrictForestryId) => {
  return async (dispatch) => {
    try {
      dispatch(setWorkspaceAttr({altDistrictForestryLoading: true}))
      let res = await otherDataAPI.references.getAltDistrictForestryWithQuartersList(altDistrictForestryId)
      dispatch(setWorkspaceAttr({altDistrictForestryWithQuartersList: res.data, altDistrictForestryLoading: false}))
    } catch (e) {
      dispatch(setWorkspaceAttr({altDistrictForestryLoading: false}))
      handleErrors(dispatch, e)
    }
  }
}*/

/**
 * Функция для получения всех уведомлений о пожарах для указанного идентификатора.
 *
 * @param {type} id - Идентификатор, для которого нужно получить уведомления о пожарах
 * @return {type} Результат получения уведомлений о пожарах
 */
export const getAllFireNotificationThunk = (id) => {
  return async (dispatch) => {
    try {
      let res = await dataAPI.workspaces.settings.firesNotification.getAll(id)
      dispatch(setFireNotification(res.data))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const updateTransportMonitoringDataThunk = (node) => {
  return async (dispatch) => {
    try {
      await dataAPI.workspaces.settings.transportMonitoringData.updateTransportMonitoringData(node.id)
      dispatch(setSnack('success', 'Запрос на обновление данных отправлен'))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const resetTransportMonitoringDataThunk = (node) => {
  return async (dispatch) => {
    try {
      await dataAPI.workspaces.settings.transportMonitoringData.resetTransportMonitoringData(node.id)
      dispatch(setSnack('success', 'Запрос на сброс данных отправлен'))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}
