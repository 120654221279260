import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography,} from "@mui/material";
import {getAllAllowedRentalContractPlotsThunk, getAllRentalContract, setRentalContractsAccessState} from "../../../../../../../../redux/reducers/organizationInfoReducer";
import './TableRowStyles.css';
import {Loading} from "../../../../../../../Main/Map/Common/Loading";

const headers = [
    "",
    "Договор аренды",
    "Отведена",
    "Задекларирована",
    "В разработке",
    "Разработана",
    "Идёт вывозка",
    "Закрыта",
    "В архиве"
];

const headerKeys = [
    "",
    "number",
    "show_reserved_plots",
    "show_declared_plots",
    "show_development_plots",
    "show_developed_plots",
    "show_removal_plots",
    "show_closed_plots",
    "show_archive_plots"
];

const HeaderCell = ({header, onHeaderChange, allChecked}) => {
    return (
        <TableCell>
            {header === "Договор аренды" || header === "" ? header : (
                <label>
                    <Checkbox
                        checked={allChecked}
                        onChange={() => onHeaderChange(header)}
                    />
                    {header}
                </label>
            )}
        </TableCell>
    );
};

const SecondTabAccessPlots = ({user}) => {
    const dispatch = useDispatch();
    const rentalContractsPlots = useSelector(state => state.organizationInfoReducer.allRentalContract.rental_contracts_plots);
    const isLoading = useSelector(state => state.organizationInfoReducer.isLoading);
    const [tableData, setTableData] = useState([]);
    const isRowChecked = (row) => headerKeys.slice(2).some(key => row[key]);
    const handleRowCheckboxChange = (rowIndex) => {
        const row = tableData[rowIndex];
        const allChecked = !headerKeys.slice(2).every(key => row[key]); // Проверка, если все чекбоксы в строке активированы
        const updatedData = tableData.map((row, i) =>
            i === rowIndex
                ? headerKeys.slice(2).reduce((acc, key) => {
                    acc[key] = allChecked;
                    return acc;
                }, { ...row })
                : row
        );

        setTableData(updatedData);
        dispatch(getAllRentalContract({ rental_contracts_plots: updatedData }));
        dispatch(setRentalContractsAccessState("plots", true));
    };

    useEffect(() => {
        dispatch(getAllAllowedRentalContractPlotsThunk(user.id));
    }, [dispatch, user.id]);

    useEffect(() => {
        setTableData(rentalContractsPlots || []);
    }, [rentalContractsPlots]);

    const handleHeaderChange = (header) => {
        const key = headerKeys[headers.indexOf(header)];
        const allChecked = !tableData.every(row => row[key]);
        const updatedData = tableData.map(row => ({
            ...row,
            [key]: allChecked
        }));
        setTableData(updatedData);
        dispatch(getAllRentalContract({rental_contracts_plots: updatedData}))
        dispatch(setRentalContractsAccessState('plots', true));
    };

    const handleCheckboxChange = (rowIndex, columnIndex) => {
        const key = headerKeys[columnIndex];
        const updatedData = tableData.map((row, i) => i === rowIndex ? {
            ...row,
            [key]: !row[key]
        } : row);
        setTableData(updatedData);

        dispatch(getAllRentalContract({rental_contracts_plots: updatedData}))
        dispatch(setRentalContractsAccessState('plots', true));
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : rentalContractsPlots?.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                <HeaderCell
                                    key={index}
                                    header={header}
                                    onHeaderChange={handleHeaderChange}
                                    allChecked={
                                        index === 0
                                            ? false
                                            : tableData.length > 0 &&
                                            tableData.some(row => row[headerKeys[index]])
                                    }
                                />
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((row, rowIndex) => (
                            <TableRow key={rowIndex} className="hoverTableRow">
                                <TableCell>
                                    <Checkbox
                                        checked={isRowChecked(row)}
                                        onChange={() => handleRowCheckboxChange(rowIndex)}
                                    />
                                </TableCell>
                                {headerKeys.slice(1).map((key, cellIndex) => (
                                    <TableCell key={cellIndex}>
                                        {cellIndex === 0 ? (
                                            row[key]
                                        ) : (
                                            <Checkbox
                                                checked={row[key]}
                                                onChange={() => handleCheckboxChange(rowIndex, cellIndex + 1)}
                                            />
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Typography>Договоры аренды отсутствуют</Typography>
            )}
        </>
    );
};

export default SecondTabAccessPlots;
