import {Alert, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography,} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {getAllFireNotificationThunk, setFireNotification} from "./workspaceReducer";
import React, {useEffect} from "react";
import {firesNotificationEnableNotificationString, firesNotificationRadius, firesNotificationTime} from "../../../../Map/Common/Strings_RU";
import DynamicList from "./DynamicList";
import './css.css';

export function WSFiresSettingsTab({workspace}) {
    const firesDataState = useSelector((state) => state.workspaceReducer.firesData);
    //const [fireData, setFireData] = useState(null)
    const dispatch = useDispatch()
    const selectNumbers = [1, 2, 3, 4, 5]
    const maxRadius = 20000;

    function setTime(e, menuitem) {
        const newValue = menuitem.props.value;
        const newFireData = {...firesDataState, time: newValue}
        dispatch(setFireNotification(newFireData))
    }

    useEffect(() => {
        dispatch(getAllFireNotificationThunk(workspace.id))
    }, [workspace.id]);

    const disableNotification = (value) => {
        if (!value) {
            // Тут бэкенд не принимает пустые значения на radius и time. Поэтому ставим заглушки в виде значений.
            dispatch(setFireNotification({...firesDataState, radius: 1, time: 1, notification_on: value, people: []}))
            return
        }
        dispatch(setFireNotification({...firesDataState, notification_on: value}))
    }

    return (
        <Stack direction={'column'} spacing={2}>
            {firesDataState?.notification_on ?
              <Alert severity="info">
                  Спутники обновляют данные в среднем каждые 12 часов. Подробнее об особенностях спутникового
                  мониторинга можно почитать на&nbsp;
                  <a target="_blank" className={'wp-fire-link'} href={"https://www.delyana.ru/wildfires-control#faq"}>нашем сайте</a> или на&nbsp;
                  <a target="_blank" className={'wp-fire-link'} href={"https://www.earthdata.nasa.gov/faq/firms-faq"}>сайте NASA</a> (англ.).
              </Alert> : null
            }
            <FormControlLabel
                style={{width: 'fit-content'}}
                control={
                    <Switch
                        checked={firesDataState?.notification_on || false}
                        onChange={() => disableNotification(!firesDataState?.notification_on)}
                    />
                }
                label={
                    <Typography>{firesNotificationEnableNotificationString}</Typography>
                }
            />

            <Stack direction={'row'} spacing={1}>
                <TextField
                    disabled={!firesDataState?.notification_on}
                    autoComplete={'off'}
                    sx={{flex: 0.5}}
                    size={'small'}
                    value={firesDataState?.radius || ''} // Убедитесь, что value не будет undefined
                    label={firesNotificationRadius}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        // Разрешаем поле быть пустым для нового ввода
                        if (newValue === '') {
                            dispatch(setFireNotification({...firesDataState, radius: ''}));
                        } else if (/^\d*$/.test(newValue)) {
                            const numericValue = parseInt(newValue, 10);
                            // Проверяем, не превышает ли число максимально допустимого значения
                            if (!isNaN(numericValue) && numericValue <= maxRadius) {
                                // Преобразовываем числовое значение в строку перед обновлением состояния
                                dispatch(setFireNotification({...firesDataState, radius: numericValue.toString()}));
                            } else if (numericValue > maxRadius) {
                                // Если значение превышает maxRadius, устанавливаем maxRadius и преобразуем в строку
                                dispatch(setFireNotification({...firesDataState, radius: maxRadius.toString()}));
                            }
                        }
                    }}
                />

                <FormControl sx={{flex: 0.5}}>
                    <InputLabel id="fires-settings-select-label" size={'small'} style={{
                        backgroundColor: 'white',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                    }}>{firesNotificationTime}</InputLabel>
                    <Select
                        disabled={!firesDataState?.notification_on}
                        labelId={'fires-settings-select-label'}
                        size={'small'}
                        value={firesDataState?.time || ''} // Задайте дефолтное значение, если firesDataState?.time неопределено
                        onChange={setTime}
                        label={firesNotificationTime} // Убедитесь, что добавили проп label для совместимости с MUI v5
                    >
                        {selectNumbers.map(number =>
                            <MenuItem value={number} key={number}>{number}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Stack>
            <DynamicList disabled={!firesDataState?.notification_on}/>
        </Stack>
    )
}
