import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {CustomTabPanel} from "../../../CustomTabPanel";
import Dialog from "@mui/material/Dialog";
import {DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {useState} from "react";
import {EmployeesTable} from "./EmployeesTable";
import {CASPermissionsTable} from "./CASPermissionsTable";
import {useDispatch} from "react-redux";
import {setSnack} from "../../../../Main/Map/Common/Dialog/Snack/snackReducer";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export function BasicTabs({workspace, curTab, setCurTab, employees, employeesCas, changed, handleSave}) {
  const [openDialog, setOpenDialog] = useState(false)
  const [isChange, setIsChanged] = changed;
  const [newTabIndex, setNewTabIndex] = useState(0)
  const dispatch = useDispatch()

  function tabChange(event, newValue) {
    if (isChange) {
      if (newValue === 1) {
       dispatch(setSnack('error', 'Сначала сохраните изменения.'))
      } else {
        setOpenDialog(true)
        setNewTabIndex(newValue)
      }
    } else {
      setCurTab(newValue)
    }
  }

  function handleDialogClose(save) {
    if (save) {
      handleSave()
      setCurTab(newTabIndex)
    }
    setOpenDialog(false)
  }

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={curTab} onChange={tabChange}>
          <Tab label="Сотрудники" {...a11yProps(0)} />
          <Tab label="Доступ к делянам" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={curTab} index={0}>
        <EmployeesTable workspace={workspace} employees={employees} setChanged={setIsChanged}/>
      </CustomTabPanel>
      <CustomTabPanel value={curTab} index={1}>
        <CASPermissionsTable workspace={workspace} employeesCas={employeesCas} setChanged={setIsChanged}/>
      </CustomTabPanel>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{"Есть несохранённые изменения"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Вы хотите сохранить перед переходом на другую вкладку?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)}>Назад</Button>
          <Button onClick={() => handleDialogClose(true)} autoFocus>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
