import L from 'leaflet';
import {dataAPI} from "../../../../../../api/api";
import {getMap1} from "../../../../Map/GlobalObjects";
import {getPillarPopup, prepareToOpenPopup} from "../../../../Map/MapPopup/popupFunctions";
import {setSnack} from "../../../../Map/Common/Dialog/Snack/snackReducer";
import {pillarDeletingConfirmation, pillarRemovingString,} from "../../../../Map/Common/Strings_RU";
import {hideMapDialog, showMapDialog} from "../../../../Map/Common/Dialog/DialogReducer";
import {getProjects} from "../../Projects/ProjectsCommon";
import {closeMapPopup, showMapPopup} from "../../../../Map/MapPopup/mapPopup";
import {loadRightPanelData} from "../../../../RightPanel/right_panel";
import {resetRightPanelData} from "../../../../../../redux/reducers/rightPanelReducer";
import {dispatch} from "../../../../../Common/misc_functions";
import {changePillarsAttr, refreshPillarsTree} from "./pillarsReducer";
import {handleErrors} from "../../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {collapsePillarsNode, getScroll} from "./PillarsTree";
import {geoJSONdefaultPrecision} from "../../../../Map/Common/Defaults";

const pillarsTemplate = {
  pillarsByWorkspaces: {}, //array of wPillarTemplate
}
let pillars = null;
const wPillarTemplate = {
  data: [],
  collapsed: false,
  visible: true,
  blind_visible: true,
  type: 'pillars_group',
  group: null,
  blindGroup: null,
  error: null,
}

/**
 * Возвращает весь обьект столбов
 * @returns {object}
 */
export function getPillars() {
  return pillars;
}

/**
 * Ищет индекс слоя в data воркспейса
 * @param pillarLayer - слой
 * @returns {number}
 */
export function getPillarIndexInWorkspaceData(pillarLayer) {
  const workspace = pillars.pillarsByWorkspaces[pillarLayer.elz_parent_node.id]
/*  if (!workspace)
    return -1;*/
  return workspace.data.findIndex(item => pillarLayer === item)
}

/**
 * Навсегда удаляет слой с карты
 * @param layer - слой маркера столба
 * @param removeNode - нода
 */
export function deletePillarFromMap(layer, removeNode = true) {
  const workspace = pillars.pillarsByWorkspaces[layer.elz_parent_node.id]
  if (workspace) {
    const ind = workspace.data.findIndex((p) => p === layer)
    if (ind > -1) {
      if (removeNode) {
        workspace.data.splice(ind, 1)
      }
      layer.off('click')
      removePillarBlindLayer(layer)
      workspace.blindGroup.removeLayer(layer)
      workspace.group.removeLayer(layer)
    }
  }
}

/**
 * Запрос на удаление столба
 * @param layer - слой столба
 */
export function deletePillarFromWorkspaceWithDialog(layer) {
  const dispatch = window.elz_dispatch;
  const buttons = {'Да': {color: 'error'}, 'Нет': {color: 'success'}}

  dispatch(showMapDialog('warning', pillarRemovingString, pillarDeletingConfirmation, buttons, (result) => {
    dispatch(hideMapDialog())
    if (result === 'Да') {
      const workspaceID = layer.elz_parent_node.id;
      const workspace = pillars.pillarsByWorkspaces[workspaceID]
      //debugger
      const ind = workspace.data.findIndex((n) => n === layer)
      if (ind > -1) {
        dataAPI.pillars.delete(layer.elz_properties.id).then(() => {
          removePillarBlindLayer(layer)
          deletePillarFromMap(layer)
          updatePillarsReactWorkspace(workspaceID)
          const element = window.store.getState().rightPanelReducer["selectedElement"];
          if (element && element.id === layer.elz_properties.id)
            dispatch(resetRightPanelData())
          dispatch(refreshPillarsTree())
          dispatch(setSnack('success', 'Квартальный столб удален'))
        }).catch(err => {
          handleErrors(dispatch, err)
        })
      }
    }
  }))
}

/**
 * Создает divIcon для слоя макера
 * @returns L.divIcon
 */
function getMarkerDiv() {
  return L.divIcon({
    html: '<svg\n' +
      '   width="18"\n' +
      '   height="24"\n' +
      '   viewBox="0 0 9 24"' +
      '  <g\n' +
      '     id="layer1"\n' +
      '     transform="matrix(1,0,0,1.117096,-3.7585008,-0.49590526)">\n' +
      '    <path\n' +
      '       style="fill:#ff0000;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"\n' +
      '       d="m 12.751308,4.4510071 0.0043,17.5441389 -8.9954765,4.38e-4 -0.00159,-17.4767877 C 6.0860825,2.4198451 6.91139,1.6552994 8.2282985,0.46774554 Z"\n' +
      '       />\n' +
      '    <path\n' +
      '       style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"\n' +
      '       d="m 12.38066,12.55745 -8.1563324,2.239009 0.00545,2.571988 8.1486404,-2.244692 z"\n' +
      '       />\n' +
      '    <path\n' +
      '       style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"\n' +
      '       d="m 12.394437,8.8324395 -8.1918924,2.2390095 0.00547,2.571988 8.1841674,-2.244692 z"\n' +
      '       />\n' +
      '  </g>',
    iconSize: [9, 24],
    iconAnchor: [9, 24],
    className: 'no-back-no-border',
  })
}

/**
 * Ищет ноду воркспейса по id
 * @param id
 * @returns {*|null}
 */
export function getPillarWorkspaceNodeByID(id) {
  return Object.values(pillars.pillarsByWorkspaces).find(wp => wp.id === id) || null;
}

/**
 * Переход к нужному слою в списке reactArray
 * @param layer - основной leaflet слой столба
 */
function goToLayerInReactArray(layer) {
  let reactArray = window.store.getState()["pillarsReducer"]["reactArray"]
  const curPage = window.store.getState()["leftDrawerReducer"]["curTab"]
  const curSubPage = window.store.getState()["infrastructureReducer"]["curTab"]
  if (reactArray?.length && curPage === 'infrastructure' && curSubPage === 'pillars') {
    const workspaceID = layer.elz_parent_node.id;
    const wpIndex = reactArray.findIndex(item => item.id === workspaceID)
    if (wpIndex) {
      const wpNode = reactArray[wpIndex]
      if (!wpNode.collapsed) {
        collapsePillarsNode(wpNode)
      }
      reactArray = window.store.getState()["pillarsReducer"]["reactArray"]
      let ind = wpIndex + 1;
      while(ind < reactArray.length && layer !== reactArray[ind]) {
        ind++;
      }
      setTimeout(() => {
        const scroll = getScroll()
        if (scroll) {
          scroll({index: ind, align: 'center'})
        }
      }, 0)
    }
  }
}

/**
 * Обработчик клика по столбу
 * @param event - leaflet ивент
 */
function pillarClickHandler(event) {
  const layer = event.target;
  if (!prepareToOpenPopup(event))
    return;
  const dispatch = window.elz_dispatch;
  const interactive = {
    pillar_delete_button: {
      type: 'click',
      f: () => {
        closeMapPopup()
        deletePillarFromWorkspaceWithDialog(layer.options.pane === 'pillars' ? layer : layer.elz_parent_layer)
      }
    },
    pillar_visible_button: {
      type: 'click',
      f: () => {
        closeMapPopup()
        const left = layer.options.pane === 'pillars';
        if (left) {
          layer.elz_hide = true;
          pillars.pillarsByWorkspaces[layer.elz_parent_node.id].group.removeLayer(layer)
        } else {
          getMap1().removeLayer(layer)
          layer.elz_parent_layer.elz_blind_layer = null;
        }
        dispatch(refreshPillarsTree())
      }
    },
  }
  const html = getPillarPopup(layer, interactive)
  showMapPopup(event.latlng, html, interactive)
  loadRightPanelData('Pillar', layer, layer.elz_properties.id, false)
  let listLayer = layer;
  if (layer.elz_parent_layer)
    listLayer = layer.elz_parent_layer;
  goToLayerInReactArray(listLayer)
  dispatch(changePillarsAttr({selectedNode: listLayer}))
}

/**
 * Создает слой маркера из feature
 * @param feature - feature geoJson
 * @param pane - панель
 * @param workspaceNode
 * @returns {object}
 */
export function createPillarMarker(feature, pane, workspaceNode) {
  const coords = [feature.geometry.coordinates[1], feature.geometry.coordinates[0]]
  const lay = L.marker(coords, {icon: getMarkerDiv(), pane: pane, bubblingMouseEvents: true})

  lay.elz_properties = {
    id: feature.id,
    name: feature.name,
    x_quarters: feature.x_quarters,
  }
  lay.elz_parent_node = workspaceNode;
  lay.on('click', pillarClickHandler)
  const blindEnabled = window.store.getState().blindReducer.enabled;
  if (blindEnabled)
    addPillarBlindLayer(lay)
  return lay;
}

/**
 * Сохраняет слой на сервре
 * @param layer {object} - leaflet слой столба
 * @param forceRPUpdate {boolean} - форсироать обновление правой панели
 */
export function savePillarOnServer(layer, forceRPUpdate = true) {
  const id = layer.elz_properties.id;
  const feature = layer.toGeoJSON(geoJSONdefaultPrecision)
  const json = {
    name: layer.elz_properties.name,
    geometry: feature.geometry,
    rental_contract: layer.elz_parent_node.id,
  }
  layer.elz_saving = true;
  dispatch(refreshPillarsTree())
  dataAPI.pillars.patch(id, json).then(() => {
    delete layer.elz_rename;
    delete layer.elz_saving;
    delete layer.elz_saving_error;
    const element = window.store.getState().rightPanelReducer["selectedElement"]
    if (forceRPUpdate || (element && element.id !== layer.elz_properties.id))
      loadRightPanelData('Pillar', layer, layer.elz_properties.id)
    dispatch(refreshPillarsTree())
  }).catch(err => {
    delete layer.elz_rename;
    layer.elz_saving_error = true;
    handleErrors(dispatch, err)
    dispatch(refreshPillarsTree())
  })
}

/**
 * Создает новый полигон по слою.
 * @param marker - слой
 * @returns L.Marker
 */
export function clonePillarMarker(marker) {
  return L.marker(marker.getLatLng(), {icon: getMarkerDiv()})
}

/**
 * Добавляет слой в ноду воркспейса. Cоздает ноду, если её нет. Не изменяет карту.
 * @param workspaceId id воркспейса
 * @param layer - слой
 * @returns {*} - нода
 */
export function addLayerToWorkspace(workspaceId, layer) {
  let wpillars = pillars.pillarsByWorkspaces[workspaceId]
  wpillars.data.unshift(layer)
  return wpillars;
}

/**
 * Удаляет все столбы и ноды
 */
export function resetPillars() {
  const map = getMap1()
  removeAllPillarsBlindLayers()
  Object.values(pillars.pillarsByWorkspaces).map((value) => {
    value.data.map(pillar => {
      pillar.off('click')
      value.group.removeLayer(pillar)
    })
    map.removeLayer(value.group)
    map.removeLayer(value.blindGroup)
  })
  pillars = null;
}

/**
 * Сбрасывает выделенный элемент (так же для дерева проектов)
 * @param resetProjects - если true сбрасывать также в дереве проектов
 */
export function resetPillarsSelection(resetProjects = true) {
  const projects = getProjects()
  if (resetProjects)
    projects.selectedNode = null;
  pillars.selectedNode = null;
}

/**
 * Создает воркспейс, или возвращает уже существующий
 * @param workspaceId - id воркспейса
 * @param name
 * @param is_mobile
 * @param addToReact
 * @returns {*} - нода (воркспейс)
 */
function createPillarsWorkspaceIfNotExists(workspaceId, name, is_mobile, addToReact = false) {
  let wpillars = pillars.pillarsByWorkspaces[workspaceId]
  if (!wpillars) { //creating if not exists
    wpillars = structuredClone(wPillarTemplate)
    wpillars.id = workspaceId;
    wpillars.name = name;
    wpillars.is_mobile = is_mobile;
    pillars.pillarsByWorkspaces[workspaceId] = wpillars;
    wpillars.group = L.layerGroup()
    wpillars.blindGroup = L.layerGroup()
    getMap1().addLayer(wpillars.group)
    getMap1().addLayer(wpillars.blindGroup)
  }
  if (addToReact) {
    const arr = window.store.getState()["pillarsReducer"]["reactArray"];
    arr.push(wpillars)
    const dispatch = window.elz_dispatch;
    dispatch(refreshPillarsTree())
  }
  return wpillars;
}

/**
 * Ищет слой столба по id
 * @param id {number} - ID столба
 */
export function searchPillarLayerByID(id) {
  const workspaces = Object.values(pillars.pillarsByWorkspaces)
  let res = null;
  let wind = 0;
  while (wind < workspaces.length) {
    const workspace = workspaces[wind]
    const layer = workspace.data.find(layer => layer.elz_properties.id === id)
    if (layer) {
      wind = workspaces.length;
      res = layer;
    }
    wind++;
  }
  return res;
}

/**
 * Загрузка дорог с сервера
 * @returns {Promise<void>}
 */
export async function loadPillars() {
  if (!pillars)
    pillars = structuredClone(pillarsTemplate)
  dispatch(changePillarsAttr({loading: true, loaded: false, loadingError: false, reactArray: []}))
  await dataAPI.pillars.getAll().then(res => {
    const arr = res.data.map(workspace => {
      /**
       * @type {object}
       */
      const workspaceNode = createPillarsWorkspaceIfNotExists(workspace.id, workspace.name, workspace.is_mobile)
      workspace.data.forEach(feature => {
          const lay = createPillarMarker(feature, 'pillars', workspaceNode)
          workspaceNode.data.push(lay)
          workspaceNode.group.addLayer(lay)
      })
      return workspaceNode;
    })
    dispatch(changePillarsAttr({loading: false, loaded: true, loadingError: false, reactArray: arr}))
  }).catch(err => {
    console.error(err)
    dispatch(changePillarsAttr({loading: false, loaded: false, loadingError: true, reactArray: []}))
  })
}

//Функции шторки
/**
 * Создает и добавляет на карту шторочный слой из основного
 * @param layer
 */
export function addPillarBlindLayer(layer) {
  const workspaceNode = layer.elz_parent_node;
  const blind_lay = L.marker(layer.getLatLng(), {icon: getMarkerDiv(), pane: 'blind_pillars'})
  blind_lay.elz_properties = layer.elz_properties;
  blind_lay.on('click', pillarClickHandler)
  layer.elz_blind_layer = blind_lay;
  layer.elz_blind_layer.elz_parent_layer = layer;
  workspaceNode.blindGroup.addLayer(blind_lay)
}

/**
 * Удаляет слой из шторки карты по основному слою
 * @param lay - основной слой (leaflet)
 */
export function removePillarBlindLayer(lay) { //удаляет доп. слой для шторки с карты
  const wpillar = pillars.pillarsByWorkspaces[lay.elz_parent_node.id]
  const blind_lay = lay.elz_blind_layer;
  if (blind_lay) {
    wpillar.blindGroup.removeLayer(blind_lay)
    blind_lay.off('click')
    delete lay.elz_blind_layer;
  }
}

/**
 * Удаляет все слои всех воркспейсов из шторки карты
 */
export function removeAllPillarsBlindLayers() {
  Object.values(pillars.pillarsByWorkspaces).map(workspace => {
    workspace.data.map(layer => {
      removePillarBlindLayer(layer)
    })
  })
}

/**
 * Добавляет все слои всех вокспесов на карту в шторку
 */
export function addAllPillarsBlindLayers() {
  Object.values(pillars.pillarsByWorkspaces).map(workspace => {
    workspace.data.map(layer => {
      if (!layer.elz_hide)
        addPillarBlindLayer(layer)
    })
  })
}

/**
 * Обновляет воркспейс в reactArray данными из pillars.pillarsByWorkspaces
 * Не рефрешит дерево
 * @param workspaceID
 */
export function updatePillarsReactWorkspace(workspaceID) {
  const workspaceNode = pillars.pillarsByWorkspaces[workspaceID]
  if (workspaceNode) {
    const reactArray = window.store.getState().pillarsReducer["reactArray"]
    const index = reactArray.findIndex(wp => wp === workspaceNode)
    if (index !== -1) {
      //пересоздаем сожержимое воркспейса, если открыто
      if (workspaceNode.collapsed) {
        //удаляем содержимое воркспейса
        let end = index + 1;
        while (end < reactArray.length && !reactArray[end].type) {
          end++;
        }
        end--;
        reactArray.splice(index + 1, end - index)
        const arr = workspaceNode.data.map(layer => layer)
        reactArray.splice(index + 1, 0, ...arr)
      }
    }
  }
}

export function deletePillarsWorkspaceFromReactArray(workspaceID) {
  const reactArray = window.store.getState()["pillarsReducer"]["reactArray"]
  if (reactArray) {
    //Идентификация чрез id безопасна, т.к. у leaflet слоёв нет id
    const wpIndex = reactArray.findIndex(wp => wp.id === workspaceID)
    if (wpIndex !== -1) {
      let end = wpIndex + 1;
      while (end < reactArray.length && !reactArray[end].id) {
        end++;
      }
      reactArray.splice(wpIndex, end - wpIndex)
      dispatch(refreshPillarsTree())
    }
  }
}

/**
 * Удаляет все стобы из вокспейса. И сам воркспейс.
 * @param workspaceID - id воркспейса
 */
export function deleteAllPillarsByWorkspaceID(workspaceID) {
  const workspace = pillars.pillarsByWorkspaces[workspaceID]
  if (workspace) {
    workspace.group.clearLayers()
    workspace.blindGroup.clearLayers()
    deletePillarsWorkspaceFromReactArray(workspaceID)
    delete pillars.pillarsByWorkspaces[workspaceID]
  }
}
