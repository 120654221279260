import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import {titleStyle, topBlockStyle, unactiveTextStyle} from '../organizationPageStyles'
import AccountsTable from "./AccountsTable/AccountsTable";
import {useDispatch, useSelector} from "react-redux";
import {setPaymentAttr} from "../../../redux/reducers/paymentReducer";
import {getTariff} from "../../Main/Map/Common/tariffs";

const CurrentTariff = () => {
  useSelector(state => state.organizationInfoReducer)
  const dispatch = useDispatch()
  const tariff = getTariff()
  const is_active = !tariff.expired;
  const paid_to = tariff.paid_to;

  const openPaymentWindowHandler = () => {
    dispatch(setPaymentAttr({showPaymentWindow: {status: true, type: 'card'}}))
  }

  return (
    <Box sx={{...topBlockStyle, flex: 1}} id={'currentTariff'}>
      <Stack spacing={2} sx={{width: '100%'}}>
        <Stack>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Typography sx={{...titleStyle}}>ТАРИФ</Typography>
              <Typography sx={{...titleStyle, color: 'black'}}>{tariff.name}</Typography>
              {!is_active &&
                <Typography sx={unactiveTextStyle} title={'Перейти к оплате'} onClick={openPaymentWindowHandler}>
                  НЕ АКТИВЕН
                </Typography>
              }
            </Stack>
            <Box>
              <Typography sx={titleStyle}>{`Действует до ${paid_to}`}</Typography>
            </Box>
          </Box>
          <Stack direction={'row'} spacing={2}>
            <Box sx={{width: 140}}>
              <Typography sx={{textDecoration: 'underline', color: 'primary.dark', fontWeight: 500}}>
                Пробный период</Typography>
            </Box>
          </Stack>
        </Stack>
        <Box>
          <AccountsTable/>
        </Box>
      </Stack>
    </Box>
  )
}

export default CurrentTariff;
