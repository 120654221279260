import React, {useState} from 'react';
import {ListItemIcon, MenuItem} from "@mui/material";
import TokenService from "../../../../api/token.service";
import {useDispatch} from "react-redux";
import LogoutIcon from '@mui/icons-material/Logout';
import {setAuthAttr} from "../../../../redux/reducers/loginReducer";
import {setExitStatus} from "../../../Main/Map/GlobalObjects";
import {hideMapDialog, showMapDialog} from "../../../Main/Map/Common/Dialog/DialogReducer";
import {
  confirmDeletePlanetable,
  confirmExitMessage,
  deletingPlanetable,
  ExitDialogTitle
} from "../../../Main/Map/Common/Strings_RU";
import {deletePlanetableThunk} from "../../../../redux/reducers/planetablesReducer";

const LogoutMenuItem = ({handleClose}) => {
  const dispatch = useDispatch()
  const buttons = {'Да': {color: 'warning'}, 'Нет': {color: 'success'}}
  const exit = () => {
    dispatch(showMapDialog('warning', ExitDialogTitle, confirmExitMessage, buttons, (e) => {
      dispatch(hideMapDialog())
      if (e === 'Да') {
        setExitStatus(true)
        dispatch(setAuthAttr({token: {access: false, refresh: false}}))
        dispatch({type: 'USER_LOGGED_OUT'})
        TokenService.removeRefreshToken();
        TokenService.removeAccessToken();
      }
    }))
    handleClose()
  }

  return (
    <>
      <MenuItem onClick={() => {
        exit()
      }}>
      <ListItemIcon>
        <LogoutIcon fontSize="small"/>
      </ListItemIcon>
        Выход
      </MenuItem>
    </>
  );
};

export default LogoutMenuItem;