import {useState} from "react";
import {TextField} from "@mui/material";

export function FTextField({value, label, style, nofocus, variant, error, _endEditing, _cancelEdit}) {
  const [valueEdit, setValueEdit] = useState(value?value:'')

  return (
    <TextField
      error = {error}
      label={label}
      autoComplete='off'
      style={{...style, width: '100%'}}
      variant={variant?variant:'standard'}
      size={'small'}
      inputRef={input => {
        if (input && !nofocus)
          input.focus()
      }}
      value={valueEdit}
      onChange={(e) => setValueEdit(e.target.value)}
      onBlur={() => _endEditing(valueEdit, 'blur')}
      onKeyDown={(event) => {
        event.stopPropagation()
        if (event.key === 'Enter') {
          _endEditing(valueEdit)
        } else
          if (event.key === 'Escape') {
            if (_cancelEdit)
              _cancelEdit(valueEdit)
        }
      }
      }
    />
  )
}
