//Все эти функции пока вызываются только из проектов. На проекты они не действуют.
//Сейчас работает только на дороги.
//Всю эту дичь нужно заменить едиными воркспейсными нодами.
import {addRoadsWorkspace, deleteRoadsWorkSpaceComplete, renameRoadsWorkspace} from "../Roads/roads";
import {deleteRentPrimeters, deleteTreeNode, getWorkspaceNodeByID} from "../Projects/ProjectsCommon";
import {deleteAllCAByRentalContractID} from "../CAs/CuttingsAreas";
import {deleteAllPillarsByWorkspaceID} from "../Infrastructure/Pillars/pillars";
import {refreshProjects} from "../Projects/projectsReducer";
import {setSnack} from "../../../Map/Common/Dialog/Snack/snackReducer";
import {deletingSuccess} from "../../../Map/Common/Strings_RU";
import {dispatch} from "../../../../Common/misc_functions";
import {dataAPI} from "../../../../../api/api";
import {handleErrors} from "../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";

/**
 * Удаляет все упоминания воркспейса.
 * @param workspaceID - id воркспейса
 */
export function deleteWorkspaceByID(workspaceID) {
  dataAPI.workspaces.delete(workspaceID).then(() => {
    //Roads
    deleteRoadsWorkSpaceComplete(workspaceID)
    //Pillars
    deleteAllPillarsByWorkspaceID(workspaceID)
    //CAs
    deleteAllCAByRentalContractID(workspaceID)
    //projects:
    const node = getWorkspaceNodeByID(workspaceID)
    if (node) {
      deleteTreeNode(node)
      dispatch(refreshProjects())
    }
    //rent borders:
    deleteRentPrimeters(node, false, true)
    deleteRentPrimeters(node, true, false)
    dispatch(setSnack('info', deletingSuccess))
    }).catch(err => {
      handleErrors(dispatch, err)
      dispatch(refreshProjects())
    })
}

/**
 * Создает воркспейс везде.
 * @param feature {object} - фича воркспейса с бекенда
 */
export function addWorkspaceEverywere(feature) {
  addRoadsWorkspace(feature)
}

/**
 * Переименовывает воркспейс везде
 * @param feature {object} - фича воркспейса с бекенда
 */
export function renameWorkspaceEverywereByID(feature) {
  renameRoadsWorkspace(feature)
}
