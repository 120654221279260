import React from 'react';
import {ReflexContainer, ReflexElement, ReflexSplitter} from "react-reflex";
import Box from "@mui/material/Box";
import {getChildren, LeftDrawer} from "./LeftPanel/LeftDrawer";
import {ELZMapCP} from "./Map/ELZMapCP";
import {Modal, Stack} from "@mui/material";
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'react-reflex/styles.css'
import {useSelector} from "react-redux";
import {LeftDrawerPanel} from "./LeftPanel/LeftDrawerPanel";
import {Loading} from "./Map/Common/Loading";
import CreateUpdatePlotModal from "./LeftPanel/CreatePlotModal/CreateUpdatePlotModal";
import ForestUseReportModal from "./ForestUseReport/ForestUseReportModal";
import IntroTour from "../IntroTourCommon/IntroTour";
import {getMap1} from "./Map/GlobalObjects";
import {getTariff} from "./Map/Common/tariffs";
import {CreateUpdateWarehouseModal} from "./LeftPanel/Context/Infrastructure/Warehouses/CreateUpdateWarehouseModal";

let panelFlex = 0.3;
const Main = (props) => {
  const stateLeft = useSelector(state => state.leftDrawerReducer)

  // Склады.
  const selectedWarehouse = useSelector((state) => state.plotDataReducer.selectedWarehouse);
  const showCreateWarehouseModal = useSelector((state) => state.plotDataReducer.showCreateWarehouseModal);
  const showUpdateWarehouseModal = useSelector((state) => state.plotDataReducer.showUpdateWarehouseModal);
  const TransitionCreateWarehouseModal = React.forwardRef(() => <CreateUpdateWarehouseModal title={'Создать склад'}/>)
  const TransitionUpdateWarehouseModal = React.forwardRef(() => <CreateUpdateWarehouseModal
    title={`Редактировать слад "${selectedWarehouse?.properties.name}"`}/>)

  // Деляны.
  const selectedPlot = useSelector((state) => state.plotDataReducer.selectedPlot);
  const showCreatePlotModal = useSelector((state) => state.plotDataReducer.showCreatePlotModal);
  const showUpdatePlotModal = useSelector((state) => state.plotDataReducer.showUpdatePlotModal);
  const TransitionCreatePlotModal = React.forwardRef(() => <CreateUpdatePlotModal title={'Создать деляну'}/>)
  const TransitionUpdatePlotModal = React.forwardRef(() => <CreateUpdatePlotModal
    title={`Редактировать деляну "${selectedPlot?.name}"`}/>)

  // Общее.
  const showForestUseReportModal = useSelector((state) => state.forestUseReportReducer.showForestUseReportModal);
  const mainPageIntroTour = useSelector((state) => state.introToursReducer.mainPageIntroTour);
  const TransitionForestUseReportModal = React.forwardRef(() => <ForestUseReportModal/>)
  const nobanner = props.nobanner;
  const setNoBanner = props.setNoBanner;
  const isMobileDevice = useSelector(state => state.userReducer.isMobileDevice)

  if (!getTariff()) {
    return (<Stack style={{height: '100%'}} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
      <Loading/>
    </Stack>)
  }

  return (
    <Box id='main_div' sx={{height: '100%', flex: '1 0 0', display: 'flex'}}>
      <Stack flexDirection={'column'} style={{flex: '1 0 0', display: 'flex'}}>
        {!isMobileDevice ? <Stack flexDirection={'row'} style={{flex: '1 0 0', display: 'flex'}}>
          <LeftDrawer/>
          {stateLeft.curTab ? <ReflexContainer orientation={'vertical'} >
            <ReflexElement
              flex={panelFlex}
              style={{overflow: 'hidden', display: 'flex'}}
              onResize={(e) => {
                panelFlex = e.component.props.flex;
              }}
            >
              <LeftDrawerPanel index={stateLeft.curTab}>
                {getChildren(stateLeft.curTab)}
              </LeftDrawerPanel>
            </ReflexElement>
            <ReflexSplitter
              className={'reflex-splitter-vertical'}
              onResize={() => {
                const map = getMap1()
                if (map) getMap1().invalidateSize()
              }}
            />
            <ReflexElement style={{overflow: 'hidden'}}>
              <ELZMapCP curTab={stateLeft.curTab} nobanner={nobanner} setNoBanner={setNoBanner}/>
            </ReflexElement>
          </ReflexContainer> : <>
            <ELZMapCP curTab={stateLeft.curTab} nobanner={nobanner} setNoBanner={setNoBanner}/>
          </>}
        </Stack> : <ELZMapCP curTab={stateLeft.curTab} nobanner={nobanner} setNoBanner={setNoBanner}/>}
      </Stack>
      <Modal open={showCreateWarehouseModal}>
        <TransitionCreateWarehouseModal/>
      </Modal>
      <Modal open={showUpdateWarehouseModal}>
        <TransitionUpdateWarehouseModal/>
      </Modal>
      <Modal open={showCreatePlotModal}>
        <TransitionCreatePlotModal/>
      </Modal>
      <Modal open={showUpdatePlotModal}>
        <TransitionUpdatePlotModal/>
      </Modal>
      <Modal open={showForestUseReportModal}>
        <TransitionForestUseReportModal/>
      </Modal>
      <IntroTour tourData={mainPageIntroTour} tourName={'mainPageIntroTour'}/>
    </Box>
  )
}
export default Main;
