import {pageTitleStyle} from "./Styles";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router";
import {urlGenerator} from "../../utils/urlGenerator";
import Stack from "@mui/material/Stack";

// Заголовок страницы + кнопка возврата на главную
export const TitleCommonBlock = ({title, /*introTour, introTourName,*/ id}) => {
  const navigate = useNavigate()
  //const dispatch = useDispatch()
  return (
    <Stack direction={'row'} id={id} style={{flex: 0}}>
      <IconButton
        sx={{...pageTitleStyle}}
        onClick={() => navigate(urlGenerator().main)}
        title={'На главную'}>
        <ArrowBackIcon/>
      </IconButton>
      <Typography sx={pageTitleStyle}>{title}</Typography>
      {/*{introTour &&
        <IconButton
          sx={{...pageTitleStyle}}
          onClick={() => {
            dispatch(setIntroTourAttr({...introTour, status: true}, introTourName))}}
          title={'Описание функционала'}>
          <ErrorOutlineOutlinedIcon fontWeight={'bold'}/>
        </IconButton>
      }*/}
    </Stack>
  )
}
