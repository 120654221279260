//NOTE: Доступна оптимизация: можно подсчитывать количество очков роли во время загрузки и сортировка будет намного быстрее
//С этим есть нюансы: узеры загружается в родителе этого компонента, не будет ли это проблемой?

import {Table, TableBody, TableContainer} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import TableHeadComponent from "./TableComponents/TableHeadComponent";
import UserRow from "./TableComponents/UserRow";
import UserForm from "./TableComponents/UserForm/UserForm";
import CreateUserRow from "./TableComponents/CreateUserRow";
import {setOrgInfoAttr} from "../../../../redux/reducers/organizationInfoReducer";
import {compareRoles} from "../../../Main/Map/Common/tariffs";

export const tableRowStyle = {
  "&:last-child td, &:last-child th": {border: 0},
  "&:hover": {backgroundColor: '#e7e7e7'},
  cursor: 'default'
}

const fieldData = [ //роль обрабатывается отдельно, так повелось
  {fieldName: "last_name", label: "Фамилия", required: true, max_length: 50, order: null},
  {fieldName: "first_name", label: "Имя", required: true, max_length: 50, order: null},
  {fieldName: "patronymic", label: "Отчество", required: false, max_length: 50, order: null},
  {fieldName: 'phone', label: "Телефон", required: true, max_length: 10, order: null},
  {fieldName: 'email', label: "Email", required: false, max_length: 50, order: null},
  {fieldName: "post", label: "Должность", required: false, max_length: 50, order: null},
]
const roleData = {fieldName: 'role', label: "Роль", order: null}

/*function calculateUserRoleScore(user) {
    let u1score = 0;
    u1score += user.is_owner?10000:0;
    u1score += user.is_admin?1000:0;
    u1score += user.is_editor?100:0;
    u1score += user.is_full_access_user?10:0;
    return u1score;
  }*/

/**
 * Таблица узеров в ЛК
 * @returns {JSX.Element|null}
 */
const EmployeesTable = () => {
  const organizationUsers = useSelector(state => state.organizationInfoReducer.organizationUsers)
  const createUserMode = useSelector(state => state.organizationInfoReducer.createUserMode)
  const selectedUser = useSelector(state => state.organizationInfoReducer.selectedUser)
  const dispatch = useDispatch()

  if (!organizationUsers) {
    return null;
  }

  function sortingChange(fieldName, sortingOrder) {
    let curField = null;
    fieldData.forEach(field => {
      if (field.fieldName === fieldName)
        curField = field;
      field.order = null;
    })
    roleData.order = null;
    if (!curField)
      curField = roleData;
    curField.order = !sortingOrder;
    const arr = [...organizationUsers]
    arr.sort((u1, u2) => {
      if (fieldName !== 'role') {
        const res = u1[fieldName].localeCompare(u2[fieldName])
        if (curField.order === false) {
          return res * -1;
        }
        return res;
      } else {
        const res = compareRoles(u1, u2)
        if (roleData.order === true) { //fixme: так и непонял почему оно тут вверх ногами
          return res * -1;
        }
        return res;
      }
    })
    dispatch(setOrgInfoAttr({organizationUsers: arr}))
  }

  return (
    <TableContainer sx={{overflowX: 'auto'}}>
      <Table size={"small"}>
        <TableHeadComponent fieldData={fieldData} roleData={roleData} sortingChange={sortingChange}/>
        <TableBody>
          {organizationUsers.map((user, i) => (
            selectedUser?.id === user.id ?
              <UserForm key={i} fieldData={fieldData} selectedUser={selectedUser}/>
              :
              <UserRow key={i} user={user} fieldData={fieldData} index={i}/>
          ))}
          {!createUserMode ? (
            <CreateUserRow numColumns={fieldData.length}/>
          ) : (
            <UserForm fieldData={fieldData}/>
          )
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default EmployeesTable;
