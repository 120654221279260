import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {useEffect, useState} from "react";
import {Autocomplete, Checkbox, TextField, Typography} from "@mui/material";
import {cannotIDo} from "../../../../../../Main/Map/Common/tariffs";
import {OwnerCrownIcon} from "../OwnerCrownIcon";
import {getOrganizationDataThunk} from "../../../../../../../redux/reducers/organizationInfoReducer";

function fixRoles(arr, user, changedRole) {
  const newArr = structuredClone(arr)
  const iAmAdmin = cannotIDo.ownerAction()
  const adminRole = newArr.find(item => item.id === 'is_admin')
  const editorRole = newArr.find(item => item.id === 'is_editor')
  const fullViewRole = newArr.find(item => item.id === 'is_full_access_user')
  const onlyMobileRole = newArr.find(item => item.id === 'only_mobile')
  const denyAcessRole = newArr.find(item => item.id === 'deny_access')

  if (adminRole && editorRole && fullViewRole && onlyMobileRole && denyAcessRole) {
    switch(changedRole) {
      case 'is_admin':
        if (!user.is_admin) {
          editorRole.checked = true;
          fullViewRole.checked = true;
          onlyMobileRole.checked = false;
        }
        break;
      case 'is_editor':
        if (user.is_editor) {
          adminRole.checked = false;
        } else {
          fullViewRole.checked = true;
          onlyMobileRole.checked = false;
        }
        break;
      case 'is_full_access_user':
        if (user.is_full_access_user) {
          adminRole.checked = false;
          editorRole.checked = false;
        } else {
          onlyMobileRole.checked = false;
        }
        break;
      case 'only_mobile':
        if (!user.only_mobile) {
          adminRole.checked = false;
          editorRole.checked = false;
          fullViewRole.checked = false;
        }
        break;
    }
    if (iAmAdmin) { //менять роль админ можно только владельцу
      adminRole.disabled = true;
    }
  } else {
    console.error('Предоставленный список ролей некорректен.')
  }
  return newArr;
}

export const RolesList = ({userObj, setUserObj}) => {
  const [roles, setRoles] = useState([]);
  const icon = <CheckBoxOutlineBlankIcon sx={{fontSize: 14}}/>
  const checkedIcon = <CheckBoxIcon sx={{fontSize: 14}}/>
  const iAmOwner = !cannotIDo.ownerAction()

  const rolesList = [
    {id: 'is_admin', name: 'Админ', checked: userObj.is_admin, disabled: !iAmOwner},
    {id: 'is_editor', name: 'Редактор', checked: userObj.is_editor, disabled: false},
    {id: 'is_full_access_user', name: 'Полный просмотр', checked: userObj.is_full_access_user, disabled: false},
    {id: 'only_mobile', name: 'Только GisApp Mobile', checked: userObj.only_mobile, disabled: false},
    {id: 'deny_access', name: 'Нет доступа', checked: userObj.deny_access, disabled: false},
  ]

  useEffect(() => {
    setRoles(rolesList)
  }, [userObj])

  function handleRoleChange(e, selectedItems) {
    let updatedRoles = roles.map((role) => {
      return {...role, checked: selectedItems.findIndex(item => item.id === role.id) !== -1}
    })

    let changedItem;
    roles.forEach(role => { //ищем в старом массиве
      const sRole = selectedItems.find(r => r.id === role.id)
      const newChecked = Boolean(sRole)
      const oldChecked = role.checked;
      if (newChecked !== oldChecked) changedItem = role;
    })

    updatedRoles = fixRoles(updatedRoles, userObj, changedItem?.id)

    setRoles(updatedRoles) //ставим роли в менюшке

    const updatedUserObj = {};
    updatedRoles.forEach((role) => {
      updatedUserObj[role.id] = role.checked;
    })
    setUserObj(prev => ({...prev, ...updatedUserObj})) //ставим роли в узере
    window.elz_dispatch(getOrganizationDataThunk())
  }

  if (userObj.is_owner) {
    return <OwnerCrownIcon />
  }

  const initialValue = roles.filter((role) => role.checked)
  return (
    <Autocomplete
      multiple
      options={roles}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={initialValue}
      getOptionDisabled={(option) => option.disabled}
      onChange={handleRoleChange}
      renderOption={(props, option) => (
        <li {...props}>
          <Checkbox
            size={'small'}
            sx={{p: 0}}
            icon={icon}
            checkedIcon={checkedIcon}
            style={{marginRight: 8}}
            checked={option.checked}
          />
          <Typography sx={{fontSize: 13}}>
            {option.name}
          </Typography>

        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Роль" variant="standard" size="small"/>
      )}
    />
  )
}
