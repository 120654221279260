import {forwardRef, useEffect, useState} from 'react';
import {Autocomplete, Checkbox, Chip, List, TextField, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import {addUsersInWorkspacesThunk} from "../../../../../redux/reducers/organizationInfoReducer";
import {compileShortName} from "../../../../Main/Map/Common/MiscFunction";
import {getTariff} from "../../../../Main/Map/Common/tariffs";

const UsersInWorkspacesEditable = ({workspace, iAmOwner}) => {
  const dispatch = useDispatch()
  const organizationUsers = useSelector(state => state.organizationInfoReducer.organizationUsers)
  const icon = <CheckBoxOutlineBlankIcon sx={{fontSize: 14}}/>
  const checkedIcon = <CheckBoxIcon sx={{fontSize: 14}}/>
  const workspaceUsers = workspace?.users;
  const [selectedUsers, setSelectedUsers] = useState([])
  const myId = getTariff().userID;

  useEffect(() => {
    workspaceUsers.length && setSelectedUsers(workspaceUsers)
  }, [workspace])

  const CustomListbox = forwardRef((props, ref) => {
    return (
      <List dense ref={ref} {...props}>
        <ListItem>
          <Button size={'small'} onClick={addAllUsersButtonHandler}>Добавить всех</Button>
        </ListItem>
        <ListItem>
          <Button size={'small'} onClick={deleteAllUsersButtonHandler}>Удалить всех</Button>
        </ListItem>
        {props.children}
      </List>
    )
  })

  const formatSelectedUsersList = () => {
    return selectedUsers.map(user => user.id)
  }

  const deleteAllUsersButtonHandler = () => {
    const users = []
    organizationUsers.forEach(user => {
      if (user.is_admin && user.id !== myId) {
        const isSelected = selectedUsers.find(suser => suser.id === user.id)
        if (isSelected)
          users.push(user)
      }
    })
    setSelectedUsers(users)
  }

  const addAllUsersButtonHandler = () => {
    const users = []
    organizationUsers.forEach(user => {
      if (user.is_admin && user.id !== myId) {
        const isSelected = selectedUsers.find(suser => suser.id === user.id)
        if (isSelected)
          users.push(user)
      } else {
        users.push(user)
      }
    })
    setSelectedUsers(users)
  }

  const onChangeHandler = (list) => {
    setSelectedUsers(list)
  }

  const onBlurHandler = () => {
    if (JSON.stringify(workspace.users) !== JSON.stringify(selectedUsers)) {
      dispatch(addUsersInWorkspacesThunk(workspace.id, formatSelectedUsersList()))
    }
  }

  let ind = 0;
  return (
    <Autocomplete
      ListboxComponent={CustomListbox}
      disableClearable
      disableCloseOnSelect
      size={"small"}
      multiple
      limitTags={4}
      options={organizationUsers || []}
      value={selectedUsers}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => compileShortName(option.first_name, option.last_name, option.patronymic) || ''}
      getOptionDisabled={(option) => (!iAmOwner && option.is_admin) && myId !== option.id}
      renderOption={(props, option, {}) => (
        <ListItem {...props} key={ind++}>
          <Checkbox
            size={'small'}
            sx={{p: 0}}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={!!selectedUsers.find(user => user.id === option.id)}
            style={{marginRight: 8}}
          />
          <Typography sx={{fontSize: 13}}>
            {compileShortName(option.first_name, option.last_name, option.patronymic)}
          </Typography>
        </ListItem>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={ind++}
            label={compileShortName(option.first_name, option.last_name, option.patronymic)}
            {...getTagProps({index})}
            onDelete={undefined}
          />
        ))
      }
      onChange={(_event, item) => {
        onChangeHandler(item)
      }}
      onBlur={onBlurHandler}
      renderInput={(params) => {
        return (
          <TextField {...params} label={'Сотрудники'}/>)
      }}
    />
  )
}

export default UsersInWorkspacesEditable;
