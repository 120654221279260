import React, {useEffect, useState} from 'react';
import {IconButton, TableCell, TableRow} from "@mui/material";
import TextField from "@mui/material/TextField";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {useDispatch, useSelector} from "react-redux";
import {
  createWorkspaceThunk,
  setOrgInfoAttr,
  updateWorkspaceThunk
} from "../../../../../redux/reducers/organizationInfoReducer";
import {setSnack} from "../../../../Main/Map/Common/Dialog/Snack/snackReducer";

const WorkspaceForm = ({workspaceObj, WorkSpacesTableColumns}) => {
  const dispatch = useDispatch()
  const editWorkspaceMode = workspaceObj?.id
  const [workspaceName, setWorkspaceName] = useState('')
  const createWorkspaceMode = useSelector(state => state.organizationInfoReducer.createWorkspaceMode)

  useEffect(() => {
    editWorkspaceMode && setWorkspaceName(workspaceObj.number)
  }, [])

  useEffect(() => {
    let textFieldElement = document.getElementById('workspaceNameInput')
    createWorkspaceMode && textFieldElement && textFieldElement.focus()
  }, [createWorkspaceMode])

  const ColumnComponent = React.memo(({columnName, workspaceName, changeWorkspaceNameHandler}) => {
    switch (columnName) {
      case 'order':
      case 'created_at':
      case 'users':
        return ''
      case 'buttons':
        return (
          <>
            <IconButton
              onClick={() => editWorkspaceMode ? updateWorkspaceHandler() : createWorkspaceHandler()}>
              <DoneIcon sx={{color: 'primary.dark'}}/>
            </IconButton>
            <IconButton onClick={undoEditWorkspace}>
              <CloseIcon sx={{color: '#A44646'}}/>
            </IconButton>
          </>
        )
    }
  })

  function changeWorkspaceNameHandler(name) {
    setWorkspaceName(name)
  }

  function undoEditWorkspace() {
    dispatch(setOrgInfoAttr({createWorkspaceMode: false, selectedWorkspace: null}))
  }

  function createWorkspaceHandler() {
    !workspaceName
      ? dispatch(setSnack('error', 'Поле не может быть пустым'))
      : dispatch(createWorkspaceThunk({number: workspaceName}))
  }

  function updateWorkspaceHandler() {
    !workspaceName
      ? dispatch(setSnack('error', 'Поле не может быть пустым'))
      : dispatch(updateWorkspaceThunk(workspaceObj.id, {...workspaceObj, number: workspaceName}))
  }

  const onKeyDownHandler = (e) => {
    switch (e.code) {
      case 'Escape':
        undoEditWorkspace()
        break
      case 'Enter':
      case 'NumpadEnter':
        editWorkspaceMode ? updateWorkspaceHandler() : createWorkspaceHandler()
        break
    }
  }

  return (
    <TableRow
      onKeyDown={onKeyDownHandler}
      sx={{"&:last-child td, &:last-child th": {border: 0,}}}>
      {WorkSpacesTableColumns.map((column) => {
          if (column.name !== 'number') {
            return (
              <TableCell key={column.name} align="left">
                <ColumnComponent key={column.name} columnName={column.name}
                                 workspaceName={workspaceName} changeWorkspaceNameHandler={setWorkspaceName}/>
              </TableCell>
            )
          } else {
            return (
              <TableCell key={column.name} align="left">
                <TextField
                  id={'workspaceNameInput'}
                  value={workspaceName}
                  variant={'standard'}
                  size={'small'}
                  inputProps={{maxLength: 50, style: {paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}}
                  onChange={(e) => changeWorkspaceNameHandler(e.target.value)}
                />
              </TableCell>
            )
          }

        }
      )}
    </TableRow>
  )
}

export default WorkspaceForm;
