import './App.css';
import {Route, Routes, useNavigate, Navigate, useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {setAuthAttr, updateToken} from "./redux/reducers/loginReducer";
import TokenService from "./api/token.service";
import {forwardRef, useEffect, useState} from "react";
import {authAPI} from "./api/api";
import {urlGenerator} from "./utils/urlGenerator";
import Container from "@mui/material/Container";
import SignIn from "./components/LoginPage/LoginFormMain";
import Main from "./components/Main/Main";
import Header from "./components/Header/Header";
import Box from "@mui/material/Box";
import Footer from "./components/Footer/Footer";
import {RegistrationFormMain} from "./components/RegistrationForm/RegistrationFormMain";
import {garbageNomNom} from "./components/Main/Map/Common/Exit";
import {getMap1, setExitStatus} from "./components/Main/Map/GlobalObjects";
import {setTariffExpired} from "./components/Main/Map/ELZMapReducer";
import {FSnack} from "./components/Main/Map/Common/Dialog/Snack/FSnack";
import {Modal, useTheme} from "@mui/material";
import {setUserDataAttr} from "./redux/reducers/userReducer";
import AboutPage from "./components/About/AboutPage";
import Planetables from "./components/Planetable/Planetables";
import {MapDialog} from "./components/Main/Map/Common/Dialog/MapDialog";
import {EnterDialog} from "./components/Main/Map/Common/Dialog/EnterDialog";
import NewOrganizationPage from "./components/NewOrganizationPage/NewOrganizationPage";
import {getTariff, setTariff} from "./components/Main/Map/Common/tariffs";
import PaymentWindow from "./components/NewOrganizationPage/PaymentWindow/PaymentWindow";
import Stockpiles from "./components/Stockpiles/Stockpiles";
import OperationalReport from "./components/OperationalReport/OperationalReport";
import {websocketConnect, websocketDisconnect} from "./redux/reducers/websocketReducer";
import {showMapDialog} from "./components/Main/Map/Common/Dialog/DialogReducer";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [nobanner, setNoBanner] = useState(false)
  const token = useSelector((state) => state.loginReducer.token);
  const userState = useSelector(state => state.userReducer?.userInfo)
  const showPaymentWindow = useSelector(state => state.paymentReducer.showPaymentWindow)
  const TransitionPaymentWindow = forwardRef(() => <PaymentWindow />)
  const orgInfo = useSelector(state => state.organizationInfoReducer.organizationData)
  const websocketConnectionStatus = useSelector(state => state.websocketReducer.connected)
  const userBlock = useSelector(state => state.userBlockReducer)

  const tariff = getTariff()
  const location = useLocation()

  window.elz_dispatch = dispatch;
  window.elz_theme = useTheme()
  window.elz_navigate = useNavigate()

  useEffect(() => {
    if (location.pathname !== urlGenerator().stockpiles && websocketConnectionStatus) {
      dispatch(websocketDisconnect())
    }
    if (location.pathname === urlGenerator().stockpiles) {
      dispatch(websocketConnect())
    }
  }, [location])

  useEffect(() => {
    //FIXME проверка на мобильный екран не корректная!!!
    window.matchMedia('(max-width: 768px)').matches && dispatch(setUserDataAttr({isMobileDevice: true}))

    const verify = async () => {
      const refreshToken = TokenService.getLocalRefreshToken()
      if (refreshToken) {
        try {
          let verifyUser = await authAPI.verifyUser(refreshToken);
          if (verifyUser.status === 200) {
            dispatch(updateToken({refresh: true}));
          } else {
            dispatch(updateToken({refresh: false}));
            TokenService.removeRefreshToken();
            navigateToLoginPage()
            return 'login';
          }
        } catch (e) {
          console.error(e)
          dispatch(updateToken({refresh: false}));
          TokenService.removeRefreshToken();
          navigateToLoginPage()
          return 'login'
        }
      } else {
        navigateToLoginPage()
        return 'login'
      }
    }
    verify().then()
    const map = getMap1()
    if ((window.location.pathname === urlGenerator().login) && map) {
      garbageNomNom()
      setExitStatus(false)
    }
  }, [token.refresh]);

  useEffect(() => {
    if (!tariff && userState && orgInfo) {
      const res = setTariff(userState, orgInfo)
      if (res !== undefined) {
        dispatch(setTariffExpired(res))
      }
    }
  }, [orgInfo, userState])

  useEffect(() => {
    if (userBlock.blocked) {
      const buttons = userBlock.type === 'login'?{"Ok": {}}:undefined;
      dispatch(showMapDialog('error', 'Доступ запрещен', userBlock.message, buttons, () => {
        setExitStatus(true)
        dispatch(setAuthAttr({token: {access: false, refresh: false}}))
        dispatch({type: 'USER_LOGGED_OUT'})
        TokenService.removeRefreshToken()
        TokenService.removeAccessToken()
      }))
    }
  }, [userBlock])

  function navigateToLoginPage() {
    window.location.pathname !== urlGenerator().signUp && navigate(urlGenerator().login)
  }

  const theme = createTheme({
    palette: {
      primary: {
        // main: '#029760',
        main: '#1e66a5',
        dark: '#020f3d'
      },
    },
  })

  let backgroundColor = 'white';
  if (window.location.pathname === '/app/organization_info') {
    backgroundColor = '#EFEFF4';
  }

  const containerStyle = {
    flex: 1,
    overflow: 'hidden',
    padding: '6px 6px 0 6px',
    backgroundColor: backgroundColor,
    boxSizing: 'border-box',
    position: 'relative',
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{maxHeight: '100dvh', height: '100dvh', width: '100%', display: 'flex', flexDirection: 'column'}}>
        <Header backgroundColor={backgroundColor}/>
        <Container maxWidth={"auto"} style={containerStyle} id={'mainContainer'}>
          <Routes>
            <Route path={urlGenerator().main} element={<Main nobanner={nobanner} setNoBanner={setNoBanner}/>}/>
            <Route path={urlGenerator().login} element={<SignIn/>}/>
            <Route path={urlGenerator().signUp} element={<RegistrationFormMain/>} />
            <Route path={urlGenerator().newOrganizationPage} element={<NewOrganizationPage/>} />
            <Route path={urlGenerator().about} element={<AboutPage/>} />
            <Route path={urlGenerator().planetables} element={<Planetables/>} />
            <Route path={urlGenerator().stockpiles} element={<Stockpiles/>} />
            <Route path={urlGenerator().operationalReport} element={<OperationalReport/>} />
            <Route path="*" element={<Navigate to={urlGenerator().main}/>} />
          </Routes>
        </Container>
        <Footer backgroundColor={backgroundColor}/>
        <FSnack />
      </Box>
      <Modal open={showPaymentWindow.status}>
        <TransitionPaymentWindow/>
      </Modal>
      <MapDialog/>
      <EnterDialog/>
    </ThemeProvider>
  )
}

export default App;
