import React from "react";
import {urlGenerator} from "../../utils/urlGenerator";
import {useNavigate} from "react-router";
import Grid from "@mui/material/Grid";
import FooterTextItem from "./FooterTextItem";
import {useSelector} from "react-redux";
import {JivoSite} from "../Main/Map/Common/JivoSite";
import {MobileBanner2} from "../Main/Map/Common/MobileBanner2";

const footerTextStyle = {
    whiteSpace: 'nowrap', mt: 1, mb: 1, fontSize: 12,
    width: '100%', display: 'flex', justifyContent: 'center', color: 'gray',
    cursor: 'pointer', '&:hover': {textDecoration: 'underline', color: 'primary.dark'}, mr: 2
}

const jivoBlockStyle = {position: 'absolute', right: '14px', top: '-50px', zIndex: 20000}

function Footer({backgroundColor}) {
    const navigate = useNavigate()
    const isMobileDevice = useSelector(state => state.userReducer.isMobileDevice)
    const banner = isMobileDevice && window.location.pathname !== '/app/login/' && window.location.pathname !== '/app/sign_up/';

    return (
        <div style={{position: 'relative', backgroundColor: backgroundColor}}>
            {!banner
                ?
                <>
                    <Grid container sx={{dispay: 'flex', justifyContent: 'center', width: '100%'}}>
                        <FooterTextItem text={`Copyright © Delyana, ${new Date().getFullYear()}`}
                                        style={{...footerTextStyle, '&:hover': 'none', cursor: 'default'}}
                        />
                        <FooterTextItem text={`Пользовательское соглашение`}
                                        onClickHandler={() => window.open('https://www.delyana.ru/agreement', '_blank')}
                                        style={footerTextStyle}
                        />
                        <FooterTextItem text={`Техническая поддержка +7 (992) 320-09-04`}
                                        onClickHandler={() => navigate(urlGenerator().feedbackForm)}
                                        style={{...footerTextStyle, '&:hover': 'none', cursor: 'default'}}
                        />
                    </Grid>
                </>
                :
                <MobileBanner2/>
            }
            <div style={jivoBlockStyle}>
                <JivoSite/>
            </div>
        </div>
    )
}

export default Footer;
